import React from "react";
import classes from "./PlayerList.module.css";
import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import DoneIcon from "@mui/icons-material/Done";
import { toast } from "react-toastify";
import { addPlayer, removePlayer } from "../slices/userTeamSlice";
import { Button } from "primereact/button";

const PlayerList = ({
  dataItem,
  teamName,
  attackers,
  defenders,
  midfielders,
  goalkeeper,
  homeTeamLineups,
  awayTeamLineups,
}) => {
  const { team } = useSelector((state) => state.userTeamSlice);
  const { lang } = useSelector((state) => state.langSlice);
  const dispatch = useDispatch();

  const handleAddClick = (data) => {
    if (data?.position == "Goalkeeper" && goalkeeper.length == 1) {
      toast.info(
        lang == "english"
          ? "You Can Only Select 1 GoalKeeper!"
          : lang == "spanish"
          ? "¡Solo puedes seleccionar 1 portero!"
          : lang == "french"
          ? "Vous ne pouvez sélectionner qu’un seul gardien de but !"
          : lang == "arabic"
          ? "يمكنك اختيار حارس مرمى واحد فقط!"
          : "You Can Only Select 1 GoalKeeper!"
      );
      return;
    }
    if (data?.position == "Midfielder" && midfielders.length == 4) {
      toast.info(
        lang == "english"
          ? "You Can Only Select 4 Midfielders!"
          : lang == "spanish"
          ? "¡Solo puedes seleccionar 4 centrocampistas!"
          : lang == "french"
          ? "Vous ne pouvez sélectionner que 4 milieux de terrain !"
          : lang == "arabic"
          ? "يمكنك اختيار 4 لاعبين فقط في خط الوسط!"
          : "You Can Only Select 4 Midfielders!"
      );
      return;
    }
    if (data?.position == "Defender" && defenders.length == 4) {
      toast.info(
        lang == "english"
          ? "You Can Only Select 4 Defenders!"
          : lang == "spanish"
          ? "¡Solo puedes seleccionar 4 defensores!"
          : lang == "french"
          ? "Vous ne pouvez sélectionner que 4 défenseurs !"
          : lang == "arabic"
          ? "يمكنك اختيار 4 مدافعين فقط!"
          : "You Can Only Select 4 Defenders!"
      );
      return;
    }
    if (data?.position == "Attacker" && attackers.length == 2) {
      toast.info(
        lang == "english"
          ? "You Can Only Select 2 Attackers!"
          : lang == "spanish"
          ? "¡Solo puedes seleccionar 2 atacantes!"
          : lang == "french"
          ? "Vous ne pouvez sélectionner que 2 attaquants !"
          : lang == "arabic"
          ? "يمكنك اختيار مهاجمين اثنين فقط!"
          : "You Can Only Select 2 Attackers!"
      );
      return;
    }
    dispatch(addPlayer(data));
  };

  const handleRemoveClick = (id) => {
    dispatch(removePlayer(id));
  };

  const check =
    homeTeamLineups[0]?.startXI?.find(
      (data) => data?.player?.id == dataItem?.id
    ) ||
    awayTeamLineups[0]?.startXI?.find(
      (data) => data?.player?.id == dataItem?.id
    );

  return (
    <>
      <div key={dataItem?.id} className={classes.players_sub_container}>
        <div className={classes.player_info_container}>
          <div className={classes.player_image}>
            <img
              src={dataItem?.photo}
              alt={dataItem?.name}
              className={classes.img}
            />
          </div>
          <div className={classes.player_name}>
            <p className={classes.text}>{dataItem?.name}</p>
            <p className={classes.text}>({teamName})</p>
            {check && (
              <p className={classes.highlight}>
                <i
                  className={`fa fa-circle ${classes.text_danger_glow} ${classes.blink}`}
                  style={{ fontSize: "10px" }}
                ></i>
                {lang == "english"
                  ? "Playing"
                  : lang == "spanish"
                  ? "Jugando"
                  : lang == "french"
                  ? "Jouant"
                  : lang == "arabic"
                  ? "تلعب"
                  : "Playing"}
              </p>
            )}
          </div>
        </div>
        {team?.find((data) => {
          return data?.id == dataItem?.id;
        }) ? (
          <Button
            icon="pi pi-check"
            onClick={() => handleRemoveClick(dataItem?.id)}
            rounded
            size="small"
            aria-label="Added"
          />
        ) : (
          <Button
            icon="pi pi-plus"
            onClick={() =>
              handleAddClick({
                ...dataItem,
                teamName,
                captain: false,
                viceCaptain: false,
              })
            }
            rounded
            size="small"
            outlined
            aria-label="Add"
          />
        )}
      </div>
      <div className={classes.underline}></div>
    </>
  );
};

export default PlayerList;
