import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  baseUrl,
  fetchMatchContestByIdApi,
  getMatchLeaderboardApi,
  getUserTeamApi,
  matchByIdApi,
  userTeamPointsLogicApi,
} from "../api/api";
import classes from "../css/CreatedTeamPage.module.css";
import Loader from "../components/Loader";
import Layout from "../components/Layout";
import Drawer from "../components/Drawer";
import MenuTabs from "../components/MenuTabs";
import MatchLayout from "../components/MatchLayout";
import { TabPanel, TabView } from "primereact/tabview";
import { Button } from "primereact/button";
import { Fieldset } from "primereact/fieldset";
import LeaderBoard from "../components/Leaderboard/Leaderboard";
import PlayerStatsModal from "../components/PlayerStatsModal";
import MatchEvents from "../components/MatchEvents";
import Commentry from "../components/Commentry";
import MatchGridCenterLayout from "../components/Match-Container/MatchGridCenterLayout";

const CreatedTeamPage = () => {
  const [team, setTeam] = useState([]);
  const [attackers, setAttackers] = useState([]);
  const [matchContest, setMatchContest] = useState(null);
  const [midfielders, setMidfielders] = useState([]);
  const [defenders, setDefenders] = useState([]);
  const [goalkeeper, setGoalkeeper] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [leaderboard, setLeaderboard] = useState([]);
  const [homeTeamEvents2, setHomeTeamEvents2] = useState([]);
  const [awayTeamEvents2, setAwayTeamEvents2] = useState([]);
  const [homeTeamName, setHomeTeamName] = useState("");
  const [awayTeamName, setAwayTeamName] = useState("");
  const [modal, setModal] = useState({
    modal: false,
    match_fixture_id: null,
    player_id: null,
  });

  const { id, homeTeamId, awayTeamId, contestId } = useParams();

  const { user } = useSelector((state) => state.userSlice);

  const { lang } = useSelector((state) => state.langSlice);

  const navigate = useNavigate();

  const handleModal = (data) => {
    setModal({
      modal: true,
      match_fixture_id: data?.match_fixture_id,
      player_id: data?.player_id,
    });
  };

  const fetchDataFromBackend = async (loader) => {
    try {
      if (loader?.loading == true) {
        setLoading(true);
      }
      const res = await axios.get(`${baseUrl}${matchByIdApi}${id}`);
      setData(res?.data?.message[0]);
      const homeTeam = res?.data?.message[0]?.homeTeam;
      setHomeTeamName(homeTeam);
      const awayTeam = res?.data?.message[0]?.awayTeam;
      setAwayTeamName(awayTeam);

      const homeTeamEvents = res?.data?.message[0]?.events?.filter((data) => {
        return (
          data?.team?.name == homeTeam &&
          data?.type == "Goal" &&
          data?.detail != "Missed Penalty"
        );
      });

      setHomeTeamEvents2(homeTeamEvents);
      const awayTeamEvents = res?.data?.message[0]?.events?.filter((data) => {
        return (
          data?.team?.name == awayTeam &&
          data?.type == "Goal" &&
          data?.detail != "Missed Penalty"
        );
      });

      setAwayTeamEvents2(awayTeamEvents);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.data || error?.data?.message || "Something Went Wrong!"
      );
    }
  };

  const getUserTeam = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}${getUserTeamApi}`,
        {
          userId: user?.id,
          contest_id: contestId,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      setTeam(response?.data?.result[0]);
      let data = JSON.parse(response?.data?.result[0]?.team);
      setAttackers(() => {
        return data.filter((dataItem) => dataItem?.position == "Attacker");
      });
      setDefenders(() => {
        return data.filter((dataItem) => dataItem?.position == "Defender");
      });
      setMidfielders(() => {
        return data.filter((dataItem) => dataItem?.position == "Midfielder");
      });
      setGoalkeeper(() => {
        return data.filter((dataItem) => dataItem?.position == "Goalkeeper");
      });
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  const getMatchLeaderboard = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}${getMatchLeaderboardApi}`,
        {
          match_fixture_id: id,
          contest_id: contestId,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      setLeaderboard(response?.data?.result);
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  const userTeamPointsFromBackend = async (loader) => {
    try {
      if (loader?.loading == true) {
        setLoading(true);
      }
      const response = await axios.post(
        `${baseUrl}${userTeamPointsLogicApi}`,
        {
          match_fixture_id: id,
          userId: user?.id,
          contest_id: contestId,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      console.log(response, "user team points logic");
      getUserTeam();
      getMatchLeaderboard();
      setLoading(false);
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
      setLoading(false);
    }
  };

  const fetchMatchContest = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}${fetchMatchContestByIdApi}`,
        {
          contestId: contestId,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      console.log(response, "response");
      setMatchContest(response?.data?.result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  useEffect(() => {
    fetchDataFromBackend({ loading: true });
    userTeamPointsFromBackend({ loading: true });
    fetchMatchContest();

    const intervalId = setInterval(() => {
      if (!document.hidden) {
        fetchDataFromBackend({ loading: false });
        userTeamPointsFromBackend({ loading: false });
      }
    }, 15000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Layout active={4} hideMenu={true}>
      <Drawer active={4} />
      <div className={classes.container}>
        <MenuTabs />
        {loading ? (
          <Loader />
        ) : (
          <>
            <MatchGridCenterLayout>
              <MatchLayout match={data} hideButton={true} />
            </MatchGridCenterLayout>

            <div className={classes.tab_container}>
              <TabView scrollable>
                <TabPanel
                  header={
                    lang == "english"
                      ? "Your Team"
                      : lang == "spanish"
                      ? "Tu equipo"
                      : lang == "french"
                      ? "Ton équipe"
                      : lang == "arabic"
                      ? "فريقك"
                      : "Your Team"
                  }
                >
                  <>
                    <div className={classes.line_up_container}>
                      <div className={classes.line_up_sub_container}>
                        <img
                          src="/assets/images/football_ground_half.jpg"
                          alt="football ground"
                          className={classes.ground}
                        />

                        <div className={classes.sub_container}>
                          <div
                            className={classes.grid_container}
                            style={{
                              gridTemplateColumns: `repeat(${attackers.length}, 1fr)`,
                            }}
                          >
                            {attackers.map((data) => {
                              return (
                                <div
                                  className={classes.grid_sub_container}
                                  onClick={() =>
                                    handleModal({
                                      match_fixture_id: id,
                                      player_id: data?.id,
                                    })
                                  }
                                >
                                  <div className={classes.image_container}>
                                    <img
                                      src={data?.photo}
                                      alt=""
                                      className={classes.image}
                                    />
                                    <div className={classes.square}>
                                      <p className={classes.point}>
                                        {data?.point}
                                      </p>
                                    </div>
                                    {data?.captain ? (
                                      <div className={classes.square2}>
                                        <p className={classes.indicate}>C</p>
                                      </div>
                                    ) : data?.viceCaptain ? (
                                      <div className={classes.square2}>
                                        <p className={classes.indicate}>VC</p>
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className={classes.player_name}>
                                    <p className={classes.text}>{data?.name}</p>
                                  </div>
                                </div>
                              );
                            })}
                          </div>

                          <div
                            className={classes.grid_container}
                            style={{
                              gridTemplateColumns: `repeat(${midfielders.length}, 1fr)`,
                            }}
                          >
                            {midfielders.map((data) => {
                              return (
                                <div
                                  className={classes.grid_sub_container}
                                  onClick={() =>
                                    handleModal({
                                      match_fixture_id: id,
                                      player_id: data?.id,
                                    })
                                  }
                                >
                                  <div className={classes.image_container}>
                                    <img
                                      src={data?.photo}
                                      alt=""
                                      className={classes.image}
                                    />
                                    <div className={classes.square}>
                                      <p>{data?.point}</p>
                                    </div>
                                    {data?.captain ? (
                                      <div className={classes.square2}>
                                        <p>C</p>
                                      </div>
                                    ) : data?.viceCaptain ? (
                                      <div className={classes.square2}>
                                        <p>VC</p>
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className={classes.player_name}>
                                    <p>{data?.name}</p>
                                  </div>
                                </div>
                              );
                            })}
                          </div>

                          <div
                            className={classes.grid_container}
                            style={{
                              gridTemplateColumns: `repeat(${defenders.length}, 1fr)`,
                            }}
                          >
                            {defenders.map((data) => {
                              return (
                                <div
                                  className={classes.grid_sub_container}
                                  onClick={() =>
                                    handleModal({
                                      match_fixture_id: id,
                                      player_id: data?.id,
                                    })
                                  }
                                >
                                  <div className={classes.image_container}>
                                    <img
                                      src={data?.photo}
                                      alt=""
                                      className={classes.image}
                                    />
                                    <div className={classes.square}>
                                      <p>{data?.point}</p>
                                    </div>
                                    {data?.captain ? (
                                      <div className={classes.square2}>
                                        <p>C</p>
                                      </div>
                                    ) : data?.viceCaptain ? (
                                      <div className={classes.square2}>
                                        <p>VC</p>
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className={classes.player_name}>
                                    <p>{data?.name}</p>
                                  </div>
                                </div>
                              );
                            })}
                          </div>

                          <div
                            className={classes.grid_container}
                            style={{
                              gridTemplateColumns: `repeat(${goalkeeper.length}, 1fr)`,
                            }}
                          >
                            {goalkeeper.map((data) => {
                              return (
                                <div
                                  className={classes.grid_sub_container}
                                  onClick={() =>
                                    handleModal({
                                      match_fixture_id: id,
                                      player_id: data?.id,
                                    })
                                  }
                                >
                                  <div className={classes.image_container}>
                                    <img
                                      src={data?.photo}
                                      alt=""
                                      className={classes.image}
                                    />
                                    <div className={classes.square}>
                                      <p>{data?.point}</p>
                                    </div>
                                    {data?.captain ? (
                                      <div className={classes.square2}>
                                        <p>C</p>
                                      </div>
                                    ) : data?.viceCaptain ? (
                                      <div className={classes.square2}>
                                        <p>VC</p>
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className={classes.player_name}>
                                    <p>{data?.name}</p>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                    {data?.matchStatus != "Match Finished" &&
                    data?.matchTime == null ? (
                      <div className={classes.btn_container}>
                        <Button
                          className={classes.btn}
                          label={
                            lang == "english"
                              ? "Edit"
                              : lang == "spanish"
                              ? "Editar"
                              : lang == "french"
                              ? "Modifier"
                              : lang == "arabic"
                              ? "يحرر"
                              : "Edit"
                          }
                          severity="danger"
                          onClick={() =>
                            navigate(
                              `/match/create-team/${id}/${homeTeamId}/${awayTeamId}/${contestId}`
                            )
                          }
                          raised
                        />
                      </div>
                    ) : (
                      <div className={classes.btn_container}>
                        <Fieldset
                          style={{ width: "200px" }}
                          legend={
                            lang == "english"
                              ? "Your Points"
                              : lang == "spanish"
                              ? "Tus puntos"
                              : lang == "french"
                              ? "Vos points "
                              : lang == "arabic"
                              ? "نقاطك "
                              : "Your Points "
                          }
                        >
                          <p className={classes.points}>
                            {lang == "english"
                              ? "Your Points :"
                              : lang == "spanish"
                              ? "Tus puntos:"
                              : lang == "french"
                              ? "Vos points :"
                              : lang == "arabic"
                              ? "نقاطك :"
                              : "Your Points :"}{" "}
                            {team?.total_points}
                          </p>
                        </Fieldset>
                      </div>
                    )}
                  </>
                </TabPanel>
                <TabPanel
                  header={
                    lang == "english"
                      ? "Leaderboard"
                      : lang == "spanish"
                      ? "Tabla de clasificación"
                      : lang == "french"
                      ? "Classement"
                      : lang == "arabic"
                      ? "المتصدرين"
                      : "Leaderboard"
                  }
                >
                  <LeaderBoard
                    data={leaderboard}
                    contestId={contestId}
                    match={data}
                  />
                </TabPanel>
                <TabPanel
                  header={
                    lang == "english"
                      ? "Events"
                      : lang == "spanish"
                      ? "Eventos"
                      : lang == "french"
                      ? "Événements"
                      : lang == "arabic"
                      ? "الأحداث"
                      : "Events"
                  }
                >
                  <div className={classes.grid_col_2}>
                    <MatchEvents
                      team_name={homeTeamName}
                      data={homeTeamEvents2}
                    />
                    <MatchEvents
                      team_name={awayTeamName}
                      data={awayTeamEvents2}
                    />
                  </div>
                  <Commentry events={data?.events} />
                </TabPanel>
              </TabView>
            </div>
          </>
        )}
      </div>
      {modal?.modal && modal?.match_fixture_id && modal?.player_id && (
        <PlayerStatsModal modal={modal} setModal={setModal} />
      )}
    </Layout>
  );
};

export default CreatedTeamPage;
