import React, { useState } from "react";
import Layout from "../components/Layout";
import Drawer from "../components/Drawer";
import Heading from "../components/Heading";
import classes from "../css/PointSystemPage.module.css";
import { useSelector } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const PointSystemPage = () => {
  const { lang } = useSelector((state) => state?.langSlice);

  const [data, setData] = useState([
    {
      id: 1,
      pointType: "Captain Points",
      points: "2x Points",
    },
    {
      id: 2,
      pointType: "Vice Captain Points",
      points: "1.5x Points",
    },
    {
      id: 3,
      pointType: "Player Goal Points",
      points: "+10",
    },
    {
      id: 4,
      pointType: "Player Game Rating Points",
      points: "0 - 10",
    },
    {
      id: 5,
      pointType: "Shots on Goal Points",
      points: "+3",
    },
    {
      id: 6,
      pointType: "Goal Saved Points",
      points: "+10",
    },
    {
      id: 7,
      pointType: "Goal Assists Points",
      points: "+5",
    },
    {
      id: 8,
      pointType: "Ball Pass Point",
      points: "+1",
    },
    {
      id: 9,
      pointType: "Tackle Block Points",
      points: "+3",
    },
    {
      id: 10,
      pointType: "Tackle Interception Points",
      points: "+3",
    },
    {
      id: 11,
      pointType: "Dual Points",
      points: "+2",
    },
    {
      id: 12,
      pointType: "Red Card Points",
      points: "-10",
    },
    {
      id: 13,
      pointType: "Yellow Card Points",
      points: "-5",
    },
    {
      id: 14,
      pointType: "Foul Points",
      points: "-3",
    },
    {
      id: 15,
      pointType: "Goal Conceded Points",
      points: "-10",
    },
    {
      id: 16,
      pointType: "Total Minutes Played Points",
      points: "Minutes Played/10",
    },
    {
      id: 17,
      pointType: "Dribble Points",
      points: "+2",
    },
  ]);

  return (
    <Layout active={12}>
      <Drawer active={12} />
      <div className={classes.container}>
        <Heading
          title={
            lang == "english"
              ? "Fantasy Game Point System"
              : lang == "spanish"
              ? "Sistema de puntos del juego de fantasía"
              : lang == "french"
              ? "Système de points de jeu fantastique"
              : lang == "arabic"
              ? "نظام نقطة لعبة الخيال"
              : "Fantasy Game Point System"
          }
        />

        <DataTable value={data} className={classes.table}>
          <Column
            field="pointType"
            header={
              lang == "english"
                ? "Point Type"
                : lang == "spanish"
                ? "Tipo de punto"
                : lang == "french"
                ? "Type de points"
                : lang == "arabic"
                ? "نوع النقطة"
                : "Point Type"
            }
            body={(rowData) => <p>{rowData?.pointType}</p>}
            style={{
              position: "sticky",
              left: 0,
              backgroundColor: "#F9FAFB",
              zIndex: 1,
              maxWidth: "150px",
            }}
            headerStyle={{
              fontSize: "14px",
              color: "#343A40",
            }}
          />
          <Column
            field="points"
            header={
              lang == "english"
                ? "Points"
                : lang == "spanish"
                ? "Agujas"
                : lang == "french"
                ? "Points"
                : lang == "arabic"
                ? "نقاط"
                : "Points"
            }
            style={{
              fontSize: "14px",
              color: "#343A40",
            }}
            body={(rowData) => (
              <p className={classes.text}>{rowData?.points}</p>
            )}
          />
        </DataTable>
      </div>
    </Layout>
  );
};

export default PointSystemPage;
