import CryptoJS from "crypto-js";

export const decryptNumber = (encryptedNumber) => {
  console.log("en");
  const decodedString = decodeURIComponent(encryptedNumber);
  const bytes = CryptoJS.AES.decrypt(
    decodedString,
    process.env.REACT_APP_CRYPTO_KEY
  );
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

export const encryptNumber = (number) => {
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(number), process.env.REACT_APP_CRYPTO_KEY).toString();
  
  const urlSafeEncrypted = encodeURIComponent(encrypted);
  
  return urlSafeEncrypted;
};
