export default function date(matchTime) {
  const matchDateUTC = new Date(matchTime);
  const options = {
    weekday: "short",
    month: "short",
    day: "2-digit",
    year: "numeric",
  };
  let dateOnly = "";

  const language = JSON.parse(localStorage.getItem("lang"));
  let lang = "";
  if (!language) {
    lang = "english";
  } else {
    const { lang: langFromLocalstorage } = JSON.parse(
      localStorage.getItem("lang")
    );
    lang = langFromLocalstorage;
  }

  if (lang === "english") {
    dateOnly = matchDateUTC?.toLocaleString("en-US", options);
  } else if (lang === "french") {
    dateOnly = matchDateUTC?.toLocaleString("fr-FR", options);
  } else if (lang === "spanish") {
    dateOnly = matchDateUTC?.toLocaleString("es-ES", options);
  } else if (lang === "arabic") {
    dateOnly = matchDateUTC?.toLocaleString("ar-AR", options);
  } else {
    dateOnly = matchDateUTC?.toLocaleString("en-US", options);
  }

  const timeString = matchDateUTC?.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  const timeConvert = timeString?.replace("GMT+0530", "");

  const todayDate = new Date();

  const isSameDate = (firstDate, secondDate) => {
    return (
      firstDate.getFullYear() === secondDate.getFullYear() &&
      firstDate.getMonth() === secondDate.getMonth() &&
      firstDate.getDate() === secondDate.getDate()
    );
  };

  const isTomorrow = (firstDate, secondDate) => {
    const tomorrow = new Date(firstDate);
    tomorrow.setDate(tomorrow.getDate() + 1);
    return (
      tomorrow.getFullYear() === secondDate.getFullYear() &&
      tomorrow.getMonth() === secondDate.getMonth() &&
      tomorrow.getDate() === secondDate.getDate()
    );
  };

  if (isSameDate(todayDate, matchDateUTC)) {
    return {
      date:
        lang === "english"
          ? "Today"
          : lang === "french"
          ? "Aujourd'hui"
          : lang === "spanish"
          ? "Hoy"
          : lang === "arabic"
          ? "اليوم"
          : "Today",
      time: timeConvert,
    };
  } else if (isTomorrow(todayDate, matchDateUTC)) {
    return {
      date:
        lang === "english"
          ? "Tomorrow"
          : lang === "french"
          ? "Demain"
          : lang === "spanish"
          ? "Mañana"
          : lang === "arabic"
          ? "غداً"
          : "Tomorrow",
      time: timeConvert,
    };
  } else {
    return {
      date: dateOnly,
      time: timeConvert,
    };
  }
}
