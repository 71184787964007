import React from "react";
import classes from "./CircularLoading.module.css";

const CircularLoading = () => {
  return (
    <div className={classes.center_loader}>
      <div className={classes.loader}>
        <div className={classes.bar1}></div>
        <div className={classes.bar2}></div>
        <div className={classes.bar3}></div>
        <div className={classes.bar4}></div>
        <div className={classes.bar5}></div>
        <div className={classes.bar6}></div>
        <div className={classes.bar7}></div>
        <div className={classes.bar8}></div>
        <div className={classes.bar9}></div>
        <div className={classes.bar10}></div>
        <div className={classes.bar11}></div>
        <div className={classes.bar12}></div>
      </div>
    </div>
  );
};

export default CircularLoading;
