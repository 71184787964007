import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  deactivateModal: false,
};

const deactivateModalSlice = createSlice({
  name: "deactivateModalSlice",
  initialState,
  reducers: {
    setDeactivateModal: (state, action) => {
      state.deactivateModal = true;
      return state;
    },
    resetDeactivateModal: (state,action) => {
      state.deactivateModal = false;
      return state;
    },
  },
});

export const { setDeactivateModal,resetDeactivateModal } = deactivateModalSlice.actions;
export default deactivateModalSlice;
