import axios from "axios";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { baseUrl, checkPlayerStatsApi } from "../api/api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import classes from "./PlayerStatsModal.module.css";
import CircularLoading from "./loaders/CircularLoading";
import { Fieldset } from "primereact/fieldset";
import { Panel } from "primereact/panel";

const PlayerStatsModal = ({ modal, setModal }) => {
  const { user } = useSelector((state) => state?.userSlice);
  const { lang } = useSelector((state) => state?.langSlice);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [player, setPlayer] = useState({});
  const [points, setPoints] = useState({});
  const [totalPoints, setTotalPoints] = useState({});
  const [calculations, setCalculations] = useState({});
  const fetchDataFromBackend = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${baseUrl}${checkPlayerStatsApi}`,
        {
          match_fixture_id: modal?.match_fixture_id,
          player_id: modal?.player_id,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      console.log(response, "response...");
      if (response?.data?.result?.status == 0) {
        setError(true);
      } else {
        setPlayer(response?.data?.result?.player);
        setPoints(response?.data?.result?.points);
        setTotalPoints(response?.data?.result?.totalPoints);
        setCalculations(response?.data?.result?.calculations);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  useEffect(() => {
    if (modal?.modal) {
      fetchDataFromBackend();
    }
  }, [modal]);
  return (
    <Dialog
      header={
        lang == "english"
          ? "Player Overview"
          : lang == "spanish"
          ? "Descripción general del jugador"
          : lang == "french"
          ? "Aperçu du joueur"
          : lang == "arabic"
          ? "نظرة عامة على اللاعب"
          : "Player Overview"
      }
      visible={modal?.modal}
      maximizable
      style={{ width: "90vw" }}
      onHide={() =>
        setModal({
          modal: false,
          match_fixture_id: null,
          player_id: null,
        })
      }
    >
      {loading ? (
        <CircularLoading />
      ) : !error ? (
        <>
          <div className={classes.container}>
            <div className={classes.sub_container}>
              <Panel
                header={
                  <div className={classes.panel_header}>
                    <div className={classes.image_container}>
                      <img
                        src={player?.playerPhoto}
                        alt={player?.playerName}
                        className={classes.image}
                      />
                    </div>
                    <p className={classes.text}>{player?.playerName}</p>
                  </div>
                }
              >
                <div className={classes.panel_container}>
                  <div className={classes.grid}>
                    <p className={classes.text}>
                      {lang == "english"
                        ? "Total Minutes Played By Player Points"
                        : lang == "spanish"
                        ? "Minutos totales jugados por puntos de jugador"
                        : lang == "french"
                        ? "Total des minutes jouées par les points du joueur"
                        : lang == "arabic"
                        ? "إجمالي الدقائق التي لعبها نقاط اللاعب"
                        : "Total Minutes Played By Player Points"}
                      <br />({calculations?.minutes}) :
                    </p>
                    <p className={classes.text_right}>
                      {points?.minutesPoints}
                    </p>
                  </div>

                  <div className={classes.grid}>
                    <p className={classes.text}>
                      {lang == "english"
                        ? "Player Rating Points"
                        : lang == "spanish"
                        ? "Puntos de calificación del jugador"
                        : lang == "french"
                        ? "Points de classement des joueurs"
                        : lang == "arabic"
                        ? "نقاط تقييم اللاعب"
                        : "Player Rating Points"}
                      <br />({calculations?.rating}) :{" "}
                    </p>

                    <p className={classes.text_right}>{points?.ratingPoints}</p>
                  </div>
                  <div className={classes.grid}>
                    <p className={classes.text}>
                      {lang == "english"
                        ? "Shots On Goal Points"
                        : lang == "spanish"
                        ? "Tiros a puntos de gol"
                        : lang == "french"
                        ? "Tirs aux points de but"
                        : lang == "arabic"
                        ? "تسديدات على نقاط الهدف"
                        : "Shots On Goal Points"}
                      <br />({calculations?.shots}) :
                    </p>

                    <p className={classes.text_right}>{points?.shotsPoints}</p>
                  </div>
                  <div className={classes.grid}>
                    <p className={classes.text}>
                      {lang == "english"
                        ? "Goal Points"
                        : lang == "spanish"
                        ? "Puntos de gol"
                        : lang == "french"
                        ? "Points d'objectif"
                        : lang == "arabic"
                        ? "نقاط الهدف"
                        : "Goal Points"}
                      <br />({calculations?.goal}) :
                    </p>
                    <p className={classes.text_right}>{points?.goalPoints}</p>
                  </div>
                  <div className={classes.grid}>
                    <p className={classes.text}>
                      {lang == "english"
                        ? "Goal Conceded Points"
                        : lang == "spanish"
                        ? "Puntos concedidos"
                        : lang == "french"
                        ? "But Points concédés"
                        : lang == "arabic"
                        ? "النقاط التي استقبلتها الأهداف"
                        : "Goal Conceded Points"}
                      <br />({calculations?.goalsConceded}) :
                    </p>
                    <p className={classes.text_right}>
                      {points?.goalsConcededPoints}
                    </p>
                  </div>
                  <div className={classes.grid}>
                    <p className={classes.text}>
                      {lang == "english"
                        ? "Goal Assist Points"
                        : lang == "spanish"
                        ? "Puntos de asistencia de gol"
                        : lang == "french"
                        ? "Points d'assistance aux objectifs"
                        : lang == "arabic"
                        ? "نقاط مساعدة الهدف"
                        : "Goal Assist Points"}
                      <br />({calculations?.goalAssists}) :
                    </p>
                    <p className={classes.text_right}>
                      {points?.goalAssistsPoints}
                    </p>
                  </div>
                  <div className={classes.grid}>
                    <p className={classes.text}>
                      {lang == "english"
                        ? "Goal Saved Points"
                        : lang == "spanish"
                        ? "Objetivo de puntos guardados"
                        : lang == "french"
                        ? "Points enregistrés par objectif"
                        : lang == "arabic"
                        ? "النقاط المحفوظة في الهدف"
                        : "Goal Saved Points"}
                      <br />({calculations?.goalSaves}) :
                    </p>
                    <p className={classes.text_right}>
                      {points?.goalSavesPoints}
                    </p>
                  </div>
                  <div className={classes.grid}>
                    <p className={classes.text}>
                      {lang == "english"
                        ? "Ball Pass Points"
                        : lang == "spanish"
                        ? "Puntos de pase de pelota"
                        : lang == "french"
                        ? "Points de passe de balle"
                        : lang == "arabic"
                        ? "نقاط تمرير الكرة"
                        : "Ball Pass Points"}
                      <br />({calculations?.passes}) :
                    </p>
                    <p className={classes.text_right}>{points?.passesPoints}</p>
                  </div>
                  <div className={classes.grid}>
                    <p className={classes.text}>
                      {lang == "english"
                        ? "Foul Points"
                        : lang == "spanish"
                        ? "Puntos de falta"
                        : lang == "french"
                        ? "Points fautifs"
                        : lang == "arabic"
                        ? "نقاط خطأ"
                        : "Foul Points"}
                      <br />({calculations?.fouls}) :
                    </p>
                    <p className={classes.text_right}>{points?.foulsPoints}</p>
                  </div>
                  <div className={classes.grid}>
                    <p className={classes.text}>
                      {lang == "english"
                        ? "Yellow Card Points"
                        : lang == "spanish"
                        ? "Puntos de tarjeta amarilla"
                        : lang == "french"
                        ? "Points Carton Jaune"
                        : lang == "arabic"
                        ? "نقاط البطاقة الصفراء"
                        : "Yellow Card Points"}
                      <br />({calculations?.yellowCard}) :
                    </p>
                    <p className={classes.text_right}>
                      {points?.yellowCardPoints}
                    </p>
                  </div>
                  <div className={classes.grid}>
                    <p className={classes.text}>
                      {lang == "english"
                        ? "Red Card Points"
                        : lang == "spanish"
                        ? "Puntos de tarjeta roja"
                        : lang == "french"
                        ? "Points Carton Rouge"
                        : lang == "arabic"
                        ? "نقاط البطاقة الحمراء"
                        : "Red Card Points"}
                      <br />({calculations?.redCard}) :
                    </p>
                    <p className={classes.text_right}>
                      {points?.redCardPoints}
                    </p>
                  </div>
                  <div className={classes.grid}>
                    <p className={classes.text}>
                      {lang == "english"
                        ? "Tackle Block Points"
                        : lang == "spanish"
                        ? "Abordar puntos de bloqueo"
                        : lang == "french"
                        ? "S'attaquer aux points de blocage"
                        : lang == "arabic"
                        ? "معالجة نقاط الكتلة"
                        : "Tackle Block Points"}
                      <br />({calculations?.tackleBlock}) :
                    </p>
                    <p className={classes.text_right}>
                      {points?.tackleBlockPoints}
                    </p>
                  </div>
                  <div className={classes.grid}>
                    <p className={classes.text}>
                      {lang == "english"
                        ? "Tackle Interception Points"
                        : lang == "spanish"
                        ? "Abordar los puntos de intercepción"
                        : lang == "french"
                        ? "S'attaquer aux points d'interception"
                        : lang == "arabic"
                        ? "معالجة نقاط الاعتراض"
                        : "Tackle Interception Points"}
                      <br />({calculations?.tackleInterception}) :
                    </p>
                    <p className={classes.text_right}>
                      {points?.tackleInterceptionPoints}
                    </p>
                  </div>
                  <div className={classes.grid}>
                    <p className={classes.text}>
                      {lang == "english"
                        ? "Duel Points"
                        : lang == "spanish"
                        ? "Puntos de duelo"
                        : lang == "french"
                        ? "Points de duel"
                        : lang == "arabic"
                        ? "نقاط المبارزة"
                        : "Duel Points"}
                      <br />({calculations?.duel}) :
                    </p>
                    <p className={classes.text_right}>{points?.duelPoints}</p>
                  </div>
                  <div className={classes.grid}>
                    <p className={classes.text}>
                      {lang == "english"
                        ? "Dribble Points"
                        : lang == "spanish"
                        ? "Puntos de regate"
                        : lang == "french"
                        ? "Points de dribble"
                        : lang == "arabic"
                        ? "نقاط المراوغة"
                        : "Dribble Points"}
                      <br />({calculations?.dribble}) :
                    </p>
                    <p className={classes.text_right}>
                      {points?.dribblePoints}
                    </p>
                  </div>
                </div>
              </Panel>
            </div>
            <div className={classes.btn_container}>
              <Fieldset
                style={{ width: "200px" }}
                legend={
                  lang == "english"
                    ? "Total points of player"
                    : lang == "spanish"
                    ? "Puntos totales de jugador"
                    : lang == "french"
                    ? "Total de points du joueur"
                    : lang == "arabic"
                    ? "مجموع نقاط اللاعب"
                    : "Total points of player"
                }
              >
                <p className={classes.points}>
                  {lang == "english"
                    ? "Points : "
                    : lang == "spanish"
                    ? "Puntos :"
                    : lang == "french"
                    ? "Points : "
                    : lang == "arabic"
                    ? "النقاط :"
                    : "Points : "}
                  {totalPoints?.totalPoints}{" "}
                </p>
              </Fieldset>
            </div>
          </div>
        </>
      ) : (
        <div className={classes.message_box}>
          <p className={classes.message}>
            {lang == "english"
              ? "Player did not play the match..."
              : lang == "spanish"
              ? "El jugador no jugó el partido ..."
              : lang == "french"
              ? "Le joueur n'a pas joué le match..."
              : lang == "arabic"
              ? "اللاعب لم يلعب المباراة..."
              : "Player did not play the match..."}
          </p>
        </div>
      )}
    </Dialog>
  );
};

export default PlayerStatsModal;
