import React, { useEffect } from "react";
import classes from "../css/Authenticate.module.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import Lottie from "lottie-react";
import AuthenticationLoading from "../animations/authentication.json";
import LoadingText from "../animations/loading-text.json";
import { decryptNumber } from "../utils/helper";
import { baseUrl, loginApi, loginIntergatedApi } from "../api/api";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { setUser } from "../slices/userSlice";

const Authenticate = () => {
  const [searchParams] = useSearchParams();
  console.log(searchParams.get("token"), "searchParams...");
  const { lang } = useSelector((state) => state.langSlice);
  const dispatch=useDispatch();
  const navigate=useNavigate();

  const autoLogin = async (number) => {
    try {
      console.log(number, "number...");
      const response = await axios.post(loginApi, {
        msisdn: number,
      });
      if (response?.status == 200) {
        const data = { number: number };
        const res = await axios.post(`${baseUrl}${loginIntergatedApi}`, data);
        dispatch(setUser(res?.data));
        toast.success(
          lang == "english"
            ? "Logged In Successfully!"
            : lang == "spanish"
            ? "¡Inició sesión exitosamente!"
            : lang == "french"
            ? "Connecté avec succès!"
            : lang == "arabic"
            ? "تم تسجيل الدخول بنجاح!"
            : "Logged In Successfully!"
        );
        navigate("/");
      } else {
        toast.error("Login Failed!");
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  useEffect(() => {
    if (searchParams.get("token")) {
      const decryptedNumber=decryptNumber(searchParams.get("token"));
      console.log(decryptedNumber,'denumber...')
      autoLogin(decryptedNumber);
    }
  }, [searchParams]);
  return (
    <div className={classes.container}>
      <Lottie
        autoPlay
        animationData={AuthenticationLoading}
        className={classes.loading}
        loop
      />
      <Lottie
        autoPlay
        animationData={LoadingText}
        className={classes.loading_text}
        loop
      />
    </div>
  );
};

export default Authenticate;
