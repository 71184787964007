import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import Drawer from "../components/Drawer";
import Loader from "../components/Loader";
import { toast } from "react-toastify";
import axios from "axios";
import {
  baseUrl,
  deleteNotificationApi,
  userNotificationsApi,
} from "../api/api";
import { useSelector } from "react-redux";
import classes from "../css/NotificationPage.module.css";
import date from "../helpers/date";
import { useLocation, useNavigate } from "react-router-dom";
import { Paginator } from "primereact/paginator";

const NotificationsPage = () => {
  const [loading, setLoading] = useState(true);
  const [notifications, setNotification] = useState([]);
  const { user } = useSelector((state) => state?.userSlice);
  const { lang } = useSelector((state) => state.langSlice);
  const navigate = useNavigate();
  const location = useLocation();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(6);
  const [totalRecords, setTotalRecords] = useState(null);

  const getNotifications = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${baseUrl}${userNotificationsApi}`,
        {
          user_id: user?.id,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      setLoading(false);
      setNotification(response?.data?.result);
      setTotalRecords(response?.data?.result?.length);
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };
  useEffect(() => {
    getNotifications();
  }, []);

  const handleDelete = async (contest_id, alert) => {
    try {
      const response = await axios.post(
        `${baseUrl}${deleteNotificationApi}`,
        {
          user_id: user?.id,
          contest_id: contest_id,
          alert: alert,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      getNotifications();
      // hit api in layout 
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const onPageChange = (event) => {
    setLoading(true);
    setTimeout(()=>{
      setLoading(false);
    },200)
    window.scrollTo({
      top: 0,
      behavior:"instant"
    });
    navigate(`?page=${event?.page+1}`);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get("page");
    if (!page) {
      setFirst(0);
      setRows(6);
    } else {
      setFirst(6 * (page - 1));
    }
  }, [location]);

  return (
    <Layout active={9}>
      <Drawer active={9} />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className={classes.container}>
            <div className={classes.notifications}>
              {notifications.length > 0 ? (
                <>
                {notifications?.slice(first, first + rows)?.map((notification, index) => {
                  return (
                    <div className={classes.notification} key={index}>
                      <button
                        type="button"
                        className={classes.close_btn}
                        onClick={() =>
                          handleDelete(notification?.contest_id, {
                            alertAfterMatch: notification?.alertAfterMatch,
                            alertLiveMatch: notification?.alertLiveMatch,
                            afterBeforeMatch: notification?.alertBeforeMatch,
                          })
                        }
                      >
                        <i className={`fa-solid fa-xmark ${classes.icon}`} />
                      </button>
                      <p className={classes.heading}>
                        {lang == "english"
                          ? notification?.matchStatus == "Match Finished"
                            ? "Match Finished"
                            : notification?.matchStatus == "Match Not Started"
                            ? "Match About to Start"
                            : "Match Is Live"
                          : lang == "spanish"
                          ? notification?.matchStatus == "Match Finished"
                            ? "Partido terminado"
                            : notification?.matchStatus == "Match Not Started"
                            ? "Partido a punto de comenzar"
                            : "El partido está en vivo"
                          : lang == "french"
                          ? notification?.matchStatus == "Match Finished"
                            ? "Match terminé"
                            : notification?.matchStatus == "Match Not Started"
                            ? "Match sur le point de commencer"
                            : "Le match est en direct"
                          : lang == "arabic"
                          ? notification?.matchStatus == "Match Finished"
                            ? "انتهت المباراة"
                            : notification?.matchStatus == "Match Not Started"
                            ? "المباراة على وشك البدء"
                            : "المباراة حية"
                          : notification?.matchStatus == "Match Finished"
                          ? "Match Finished"
                          : notification?.matchStatus == "Match Not Started"
                          ? "Match About to Start"
                          : "Match Is Live"}
                      </p>
                      <div className={classes.match_info}>
                        <div className={classes.home_team}>
                          <img
                            src={notification?.home_team_logo}
                            alt={notification?.home_team_name}
                            className={classes.logo}
                          />
                          <p className={classes.text}>
                            {notification?.home_team_name}
                          </p>
                        </div>
                        <div className={classes.away_team}>
                          <img
                            src={notification?.away_team_logo}
                            alt={notification?.away_team_name}
                            className={classes.logo}
                          />
                          <p className={classes.text}>
                            {notification?.away_team_name}
                          </p>
                        </div>
                        <div className={classes.match_time}>
                          <p className={classes.text}>
                            {date(notification?.match_date_time)?.date},{" "}
                            {date(notification?.match_date_time)?.time}
                          </p>
                        </div>
                        <div className={classes.divider}></div>
                        <div className={classes.grid}>
                          <div className={classes.info}>
                            <p className={classes.text}>
                              {lang == "english"
                                ? "Rank:"
                                : lang == "spanish"
                                ? "Rango:"
                                : lang == "french"
                                ? "Rang:"
                                : lang == "arabic"
                                ? "رتبة:"
                                : "Rank:"}{" "}
                              {notification?.user_rank}
                            </p>
                          </div>
                          <div className={classes.info}>
                            <p className={classes.text}>
                              {lang == "english"
                                ? "Points:"
                                : lang == "spanish"
                                ? "Puntos:"
                                : lang == "french"
                                ? "Points:"
                                : lang == "arabic"
                                ? "نقاط:"
                                : "Points:"}{" "}
                              {notification?.total_points}
                            </p>
                          </div>
                        </div>

                        <div className={classes.btn_container}>
                          <button
                            type="button"
                            className={classes.btn}
                            onClick={() =>
                              navigate(
                                `/match/created-team/${+notification?.match_fixture_id}/${+notification?.home_team_id}/${+notification?.away_team_id}/${+notification?.contest_id}`
                              )
                            }
                          >
                            {lang == "english"
                              ? "Check Your Team"
                              : lang == "spanish"
                              ? "Revisa tu equipo"
                              : lang == "french"
                              ? "Vérifiez votre équipe"
                              : lang == "arabic"
                              ? "تحقق من فريقك"
                              : "Check Your Team"}
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
                 {/* <div className={classes.pagination}> */}
                  <Paginator
                    first={first}
                    rows={rows}
                    totalRecords={totalRecords}
                    onPageChange={onPageChange}
                  />
                {/* </div> */}
                </>

              ) : (
                <div className={classes.notification}>
                  <p className={classes.heading}>
                    {lang == "english"
                      ? "No Notifications Yet!"
                      : lang == "spanish"
                      ? "¡Aún no hay notificaciones!"
                      : lang == "french"
                      ? "Aucune notification pour l'instant !"
                      : lang == "arabic"
                      ? "لا توجد إخطارات حتى الآن!"
                      : "No Notifications Yet!"}
                  </p>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

export default NotificationsPage;
