import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import Drawer from "../components/Drawer";
import Loader from "../components/Loader";
import classes from "../css/Leaderboard.module.css";
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import { toast } from "react-toastify";
import axios from "axios";
import {
  baseUrl,
  monthlyLeaderboardApi,
  previousWeekLeaderboardApi,
  weeklyLeaderboardApi,
} from "../api/api";
import { useSelector } from "react-redux";
import MessageBox from "../components/MessageBox";
import date from "../helpers/date";
import MainLeaderBoard from "../components/Leaderboard/MainLeaderboard";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import CircularLoading from "../components/loaders/CircularLoading";

const LeaderboardPage = () => {
  const [month, setMonth] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [weeklyLeaderboard, setWeeklyLeaderboard] = useState([]);
  const [weekDates, setWeekDates] = useState("");
  const [previousWeekLeaderboard, setPreviousWeekLeaderboard] = useState([]);
  const [previousWeekDates, setPreviousWeekDates] = useState("");

  const [monthlyLeaderboard, setMonthlyLeaderboard] = useState([]);

  const { user } = useSelector((state) => state?.userSlice);

  const { lang } = useSelector((state) => state?.langSlice);

  const fetchWeeklyLeaderboard = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${baseUrl}${weeklyLeaderboardApi}`,
        { operator: user?.operator },
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      setWeeklyLeaderboard(response?.data?.result?.leaderboard);
      setWeekDates(response?.data?.result?.dates[0]);
      try {
        const response2 = await axios.post(
          `${baseUrl}${previousWeekLeaderboardApi}`,
          { operator: user?.operator },
          {
            headers: {
              Authorization: `Bearer ${user?.token}`,
            },
          }
        );
        setPreviousWeekLeaderboard(response2?.data?.result?.leaderboard);
        setPreviousWeekDates(response2?.data?.result?.dates[0]);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error(
          error?.response?.data?.message ||
            error?.data?.message ||
            error?.message ||
            error
        );
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };
  const fetchMonthlyLeaderboard = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${baseUrl}${monthlyLeaderboardApi}`,
        {
          operator: user?.operator,
          month: month.getMonth() + 1,
          year: month.getFullYear(),
        },
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      setMonthlyLeaderboard(response?.data?.result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  useEffect(() => {
    if (activeIndex == 0) {
      fetchWeeklyLeaderboard();
    } else {
      fetchMonthlyLeaderboard();
    }
  }, [activeIndex]);

  useEffect(() => {
    fetchMonthlyLeaderboard();
  }, [month]);

  return (
    <>
      <Layout active={11}>
        <Drawer active={11} />
        <div className={classes.container}>
          <TabView
            activeIndex={activeIndex}
            scrollable
            onTabChange={(e) => setActiveIndex(e.index)}
          >
            <TabPanel
              header={
                lang == "english"
                  ? "Weekly Leaderboard"
                  : lang == "spanish"
                  ? "Tabla de clasificación semanal"
                  : lang == "french"
                  ? "Classement hebdomadaire"
                  : lang == "arabic"
                  ? "المتصدرين الأسبوعية"
                  : "Weekly Leaderboard"
              }
            >
              <div className={classes.leaderboard_container}>
                <div className={classes.info_container}>
                  <p className={classes.heading}>
                    {lang == "english"
                      ? "Fantasy Game Leaderboard."
                      : lang == "spanish"
                      ? "Tabla de clasificación de juegos de fantasía."
                      : lang == "french"
                      ? "Classement des jeux fantastiques."
                      : lang == "arabic"
                      ? "لوحة المتصدرين للعبة الخيال."
                      : "Fantasy Game Leaderboard."}
                  </p>
                </div>
                {loading ? (
                  <CircularLoading />
                ) : (
                  <>
                    <MessageBox>
                      <p className={classes.heading}>
                        {lang == "english"
                          ? "This Weeks Leaderboard"
                          : lang == "spanish"
                          ? "Tabla de clasificación de esta semana"
                          : lang == "french"
                          ? "Classement de cette semaine"
                          : lang == "arabic"
                          ? "لوحة المتصدرين لهذا الأسبوع"
                          : "This Weeks Leaderboard"}
                      </p>
                      <p className={classes.heading}>
                        {date(weekDates?.start_date)?.date} -{" "}
                        {date(weekDates?.end_date)?.date}
                      </p>
                    </MessageBox>

                    {weeklyLeaderboard.length > 0 ? (
                      <MainLeaderBoard data={weeklyLeaderboard} />
                    ) : (
                      <MessageBox>
                        <p className={classes.heading}>
                          {lang == "english"
                            ? "No Leaderboard Data found..."
                            : lang == "spanish"
                            ? "No se encontraron datos de clasificación..."
                            : lang == "french"
                            ? "Aucune donnée de classement trouvée..."
                            : lang == "arabic"
                            ? "لم يتم العثور على بيانات المتصدرين..."
                            : "No Leaderboard Data found..."}
                        </p>
                      </MessageBox>
                    )}

                    <MessageBox>
                      <p className={classes.heading}>
                        {lang == "english"
                          ? "Previous Week Leaderboard"
                          : lang == "spanish"
                          ? "Tabla de clasificación de la semana anterior"
                          : lang == "french"
                          ? "Classement de la semaine précédente"
                          : lang == "arabic"
                          ? "لوحة المتصدرين للأسبوع السابق"
                          : "Previous Week Leaderboard"}
                      </p>
                      <p className={classes.heading}>
                        {date(previousWeekDates?.start_date)?.date} -{" "}
                        {date(previousWeekDates?.end_date)?.date}
                      </p>
                    </MessageBox>

                    {previousWeekLeaderboard.length > 0 ? (
                      <MainLeaderBoard data={previousWeekLeaderboard} />
                    ) : (
                      <MessageBox>
                        <p className={classes.heading}>
                          {lang == "english"
                            ? "No Leaderboard Data found..."
                            : lang == "spanish"
                            ? "No se encontraron datos de clasificación..."
                            : lang == "french"
                            ? "Aucune donnée de classement trouvée..."
                            : lang == "arabic"
                            ? "لم يتم العثور على بيانات المتصدرين..."
                            : "No Leaderboard Data found..."}
                        </p>
                      </MessageBox>
                    )}
                  </>
                )}
              </div>
            </TabPanel>
            <TabPanel
              header={
                lang == "english"
                  ? "Monthly Leaderboard"
                  : lang == "spanish"
                  ? "Tabla de clasificación mensual"
                  : lang == "french"
                  ? "Classement mensuel"
                  : lang == "arabic"
                  ? "المتصدرين الشهرية"
                  : "Monthly Leaderboard"
              }
            >
              <div className={classes.leaderboard_container}>
                <div className={classes.info_container}>
                  <p className={classes.heading}>
                    {lang == "english"
                      ? "Fantasy Game Leaderboard."
                      : lang == "spanish"
                      ? "Tabla de clasificación de juegos de fantasía."
                      : lang == "french"
                      ? "Classement des jeux fantastiques."
                      : lang == "arabic"
                      ? "لوحة المتصدرين للعبة الخيال."
                      : "Fantasy Game Leaderboard."}
                  </p>
                </div>
                <Calendar
                  value={month}
                  onChange={(e) => setMonth(e.value)}
                  view="month"
                  dateFormat="mm/yy"
                  showIcon
                  style={{ width: "350px" }}
                />

                <MessageBox>
                  <p className={classes.heading}>
                    {lang == "english"
                      ? "Monthly Leaderboard"
                      : lang == "spanish"
                      ? "Tabla de clasificación mensual"
                      : lang == "french"
                      ? "Classement mensuel"
                      : lang == "arabic"
                      ? "المتصدرين الشهرية"
                      : "Monthly Leaderboard"}
                  </p>
                  <p className={classes.heading}>
                    {month.getMonth() + 1} - {month.getFullYear()}
                  </p>
                </MessageBox>
                {loading ? (
                  <CircularLoading />
                ) : (
                  <>
                    {monthlyLeaderboard.length > 0 ? (
                      <MainLeaderBoard data={monthlyLeaderboard} />
                    ) : (
                      <MessageBox>
                        <p className={classes.heading}>
                          {lang == "english"
                            ? "No Leaderboard Data found..."
                            : lang == "spanish"
                            ? "No se encontraron datos de clasificación..."
                            : lang == "french"
                            ? "Aucune donnée de classement trouvée..."
                            : lang == "arabic"
                            ? "لم يتم العثور على بيانات المتصدرين..."
                            : "No Leaderboard Data found..."}
                        </p>
                      </MessageBox>
                    )}
                  </>
                )}
              </div>
            </TabPanel>
          </TabView>
        </div>
      </Layout>
    </>
  );
};

export default LeaderboardPage;
