import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import Drawer from "../components/Drawer";
import Loader from "../components/Loader";
import classes from "../css/PredictMatchPage.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  baseUrl,
  chargeNumberForPredictAndWin,
  fetchPlayersApi,
  getUserPredictionApi,
  getUserTeamApi,
  matchByIdApi,
  matchPredictionQuestionsApi,
  saveUserPredictionApi,
  teamLineupsApi,
} from "../api/api";
import axios from "axios";
import { toast } from "react-toastify";
import { resetTeam, setTeam } from "../slices/userPredictionSlice";
import { TabPanel, TabView } from "primereact/tabview";
import Heading from "../components/Heading";
import PredictPlayerList from "../components/PredictPlayerList";
import { Button } from "primereact/button";
import PredictionMenuTabs from "../components/PredictionMenuTabs";
import MatchLayout from "../components/MatchLayout";
import { Dropdown } from "primereact/dropdown";
import PredictMatchLayout from "../components/PredictMatchLayout";
import PredictContestLayout from "../components/PredictContestLayout";
import MessageBox from "../components/MessageBox";
import MatchGridCenterLayout from "../components/Match-Container/MatchGridCenterLayout";

const PredictMatchPage = () => {
  const [loading, setLoading] = useState(false);

  const { id, homeTeamId, awayTeamId, contestId } = useParams();
  const [data, setData] = useState([]);
  const [players, setPlayers] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [attackers, setAttackers] = useState([]);
  const [defenders, setDefenders] = useState([]);
  const [midfielders, setMidfielders] = useState([]);
  const [goalkeeper, setGoalkeeper] = useState([]);
  const [homeTeamLineups, setHomeTeamLineups] = useState([]);
  const [awayTeamLineups, setAwayTeamLineups] = useState([]);
  const [allPlayers, setAllPlayers] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [contestDetails, setContestDetails] = useState({});

  const [showMessage, setShowMessage] = useState(false);

  const [selectedOption1, setSelectedOption1] = useState(null);
  const [selectedOption2, setSelectedOption2] = useState(null);
  const [selectedOption3, setSelectedOption3] = useState(null);
  const [selectedOption4, setSelectedOption4] = useState(null);
  const [selectedOption5, setSelectedOption5] = useState(null);
  const [selectedOption6, setSelectedOption6] = useState(null);
  const [selectedOption7, setSelectedOption7] = useState(null);
  const [selectedOption8, setSelectedOption8] = useState(null);

  const [selectedOption_4, setSelectedOption_4] = useState({});
  const [selectedOption_7, setSelectedOption_7] = useState({});
  const [selectedOption_8, setSelectedOption_8] = useState({});

  const [isUpdating, setIsUpdating] = useState(false);

  const { user } = useSelector((state) => state.userSlice);
  const { prediction } = useSelector((state) => state.userPredictionSlice);

  const { lang } = useSelector((state) => state.langSlice);

  const fetchUserPredictions = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${baseUrl}${getUserPredictionApi}`,
        {
          user_id: user?.id,
          match_fixture_id: id,
          operator: user?.operator,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      if (response?.data?.status == 1) {
        console.log(JSON.parse(response?.data?.result?.prediction), "p");
        const userPredictions = JSON.parse(response?.data?.result?.prediction);
        userPredictions.forEach((prediction, index) => {
          const predictionKey = Object.keys(prediction)[0];
          switch (predictionKey) {
            case "prediction1":
              setSelectedOption1(prediction[predictionKey]);
              break;
            case "prediction2":
              setSelectedOption2(prediction[predictionKey]);
              break;
            case "prediction3":
              setSelectedOption3(prediction[predictionKey]);
              break;
            case "prediction4":
              setSelectedOption4(prediction[predictionKey][0]);
              setSelectedOption_4(prediction[predictionKey][1]);
              break;
            case "prediction5":
              setSelectedOption5(prediction[predictionKey]);
              break;
            case "prediction6":
              setSelectedOption6(prediction[predictionKey]);
              break;
            case "prediction7":
              setSelectedOption7(prediction[predictionKey][0]);
              setSelectedOption_7(prediction[predictionKey][1]);
              break;
            case "prediction8":
              setSelectedOption8(prediction[predictionKey][0]);
              setSelectedOption_8(prediction[predictionKey][1]);
              break;
            default:
              break;
          }
        });
        setIsUpdating(true);
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
      setLoading(false);
    }
  };

  const fetchMatchPredictionQuestions = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${baseUrl}${matchPredictionQuestionsApi}`,
        {
          match_fixture_id: id,
          operator: user?.operator,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );

      if (response?.data?.status == 1) {
        if (response?.data?.result.length > 0) {
          setContestDetails(response?.data?.result[0]);
          setQuestions(JSON.parse(response?.data?.result[0]?.questions));
        }
      } else {
        setShowMessage(true);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  const fetchPlayersFromBackend = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}${fetchPlayersApi}/${homeTeamId}/${awayTeamId}`,
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      setPlayers(response?.data?.teamData);
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  const fetchDataFromBackend = async (loadDataWithLoading) => {
    try {
      if (loadDataWithLoading?.loading) {
        setLoading(true);
      }
      const res = await axios.get(`${baseUrl}${matchByIdApi}${id}`);
      setData(res?.data?.message[0]);
      // setLoading(false);
    } catch (error) {
      // console.log(error, "error");
      setLoading(false);
      toast.error(
        error?.data || error?.data?.message || "Something Went Wrong!"
      );
    }
  };

  const fetchHomeTeamLineups = async () => {
    try {
      const res = await axios.get(
        `${baseUrl}${teamLineupsApi}/${id}/${homeTeamId}`
      );

      setHomeTeamLineups(res?.data?.response);
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.data || error?.data?.message || "Something Went Wrong!"
      );
    }
  };
  const fetchAwayTeamLineups = async () => {
    try {
      const res = await axios.get(
        `${baseUrl}${teamLineupsApi}/${id}/${awayTeamId}`
      );
      setAwayTeamLineups(res?.data?.response);
    } catch (error) {
      // console.log(error, "error");
      setLoading(false);
      toast.error(
        error?.data || error?.data?.message || "Something Went Wrong!"
      );
    }
  };

  useEffect(() => {
    fetchDataFromBackend({ loading: true });
    fetchMatchPredictionQuestions();
    fetchHomeTeamLineups();
    fetchAwayTeamLineups();
    fetchPlayersFromBackend();
    fetchUserPredictions();
    const intervalId = setInterval(() => {
      if (!document.hidden) {
        fetchDataFromBackend({ loading: false });
      }
    }, 30000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    setAttackers(() => {
      return players.flatMap((dataItem) => dataItem.attackers);
    });
    setDefenders(() => {
      return players.flatMap((dataItem) => dataItem.defenders);
    });
    setMidfielders(() => {
      return players.flatMap((dataItem) => dataItem.midfielders);
    });
    setGoalkeeper(() => {
      return players.flatMap((dataItem) => dataItem.goalkeepers);
    });
    setAllPlayers(() => {
      return players.flatMap((dataItem) => [
        ...dataItem.attackers,
        ...dataItem.defenders,
        ...dataItem.midfielders,
        ...dataItem.goalkeepers,
      ]);
    });
  }, [players]);

  const saveUserPrediction = async ({chargeUser}) => {
    setLoading(true);
    // if chargeUser is true , initially set charged to false and charge the user else set to true as user is editing his predictions and is already charged,..
    let charged=chargeUser ? false : true;
    if (chargeUser) {
      try {
        // hit chandragupt's api and charge the user....
        console.log("charging api hit--");
        const response = await axios.post(chargeNumberForPredictAndWin, {
          msisdn: user?.user_number,
          match_fixture_id: id,
        });
        charged=true;
        console.log(response, "response.... charging api...");
        toast.success(response?.data?.message);
      } catch (error) {
        setLoading(false);
        charged=false;
        console.log(error, "error--- in charging api ...");
        toast.error(
          error?.response?.data?.message ||
            error?.data?.message ||
            error?.message ||
            error
        );
      }
    }
    console.log(charged,'charged....');
    if(charged){
      try {
        const prediction = [
          selectedOption1 && { prediction1: selectedOption1 },
          selectedOption2 && { prediction2: selectedOption2 },
          selectedOption3 && { prediction3: selectedOption3 },
          selectedOption4 && { prediction4: [selectedOption4, selectedOption_4] },
          selectedOption5 && { prediction5: selectedOption5 },
          selectedOption6 && { prediction6: selectedOption6 },
          selectedOption7 && { prediction7: [selectedOption7, selectedOption_7] },
          selectedOption8 && { prediction8: [selectedOption8, selectedOption_8] },
        ];
        const dataToSend = {
          user_id: user?.id,
          user_number: user?.user_number,
          match_fixture_id: id,
          prediction: prediction,
          home_team_name: data?.homeTeam,
          away_team_name: data?.awayTeam,
          home_team_logo: data?.homeLogo,
          away_team_logo: data?.awayLogo,
          home_team_id: homeTeamId,
          away_team_id: awayTeamId,
          match_date_time: data?.time,
          total_points: 0,
          operator: user?.operator,
        };
        const response = await axios.post(
          `${baseUrl}${saveUserPredictionApi}`,
          dataToSend,
          {
            headers: {
              Authorization: `Bearer ${user?.token}`,
            },
          }
        );
        setLoading(false);
        if (isUpdating) {
          toast.success("Your Prediction has been successfully updated!");
          navigate(`/user/match/prediction/${id}/${homeTeamId}/${awayTeamId}`);
        } else {
          toast.success("Prediction Contest Joined Successfully!");
          navigate(`/user/match/prediction/${id}/${homeTeamId}/${awayTeamId}`);
        }
        console.log(response, "response...");
      } catch (error) {
        setLoading(false);
        console.log(error, "error---");
        toast.error(
          error?.response?.data?.message ||
            error?.data?.message ||
            error?.message ||
            error
        );
      }
    }
  };

  const itemTemplate = (option) => {
    return (
      <div className={classes.selector}>
        <span>{option.answer}</span>
        {option.playing && (
          <span className={classes.highlight}>
            <i
              className={`fa fa-circle ${classes.text_danger_glow} ${classes.blink}`}
              style={{ fontSize: "10px" }}
            ></i>
            {lang == "english"
              ? "Playing"
              : lang == "spanish"
              ? "Jugando"
              : lang == "french"
              ? "Jouant"
              : lang == "arabic"
              ? "تلعب"
              : "Playing"}
          </span>
        )}
      </div>
    );
  };

  return (
    <Layout active={10} hideMenu={true}>
      <Drawer active={10} />
      <div className={classes.container}>
        <PredictionMenuTabs />
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className={classes.match_container}>
              {/* <MatchGridCenterLayout> */}
              <PredictContestLayout
                match={{ ...data, contestDetail: contestDetails }}
                hideButton={true}
              />
              {/* </MatchGridCenterLayout> */}
            </div>

            {showMessage ? (
              <>
                <Heading
                  title={
                    lang == "english"
                      ? "Predict And Win"
                      : lang == "spanish"
                      ? "Predecir y ganar"
                      : lang == "french"
                      ? "Prédire et gagner"
                      : lang == "arabic"
                      ? "توقع واربح"
                      : "Predict And Win"
                  }
                />

                <div className={classes.prediction_container}>
                  <div className={classes.questions_container}>
                    <div className={classes.question}>
                      <p className={classes.question_title}>
                        There are no predictions questions yet...
                      </p>
                      <p className={classes.question_title}>
                        It will be added by the admin soon...
                      </p>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <Heading
                  title={
                    lang == "english"
                      ? "Predict And Win"
                      : lang == "spanish"
                      ? "Predecir y ganar"
                      : lang == "french"
                      ? "Prédire et gagner"
                      : lang == "arabic"
                      ? "توقع واربح"
                      : "Predict And Win"
                  }
                />

                <div className={classes.prediction_container}>
                  <div className={classes.questions_container}>
                    {questions?.length > 0 ? (
                      // &&
                      // data?.matchStatus != "Match Finished" &&
                      // data?.matchTime == null
                      questions.map((question) => {
                        return (
                          <div className={classes.question} key={question?.id}>
                            <p className={classes.question_title}>
                              {question?.name}
                            </p>
                            {question.id == 1 ? (
                              <Dropdown
                                filter
                                value={selectedOption1}
                                onChange={(e) => setSelectedOption1(e.value)}
                                options={[
                                  {
                                    answer: data?.homeTeam,
                                    questionId: 1,
                                    question: question?.name,
                                    point: 0,
                                    message: "",
                                  },
                                  {
                                    answer: data?.awayTeam,
                                    questionId: 1,
                                    question: question?.name,
                                    point: 0,
                                    message: "",
                                  },
                                  {
                                    answer: "Draw",
                                    questionId: 1,
                                    question: question?.name,
                                    point: 0,
                                    message: "",
                                  },
                                ]}
                                optionLabel="answer"
                                // virtualScrollerOptions={{ itemSize: 38 }}
                                placeholder="Select Team"
                                className="w-full md:w-14rem"
                              />
                            ) : question.id == 2 ? (
                              <Dropdown
                                filter
                                value={selectedOption2}
                                onChange={(e) => setSelectedOption2(e.value)}
                                options={[
                                  {
                                    answer: "0",
                                    questionId: 2,
                                    question: question?.name,
                                    point: 0,
                                    message: "",
                                  },
                                  {
                                    answer: 1,
                                    questionId: 2,
                                    question: question?.name,
                                    point: 0,
                                    message: "",
                                  },
                                  {
                                    answer: 2,
                                    questionId: 2,
                                    question: question?.name,
                                    point: 0,
                                    message: "",
                                  },
                                  {
                                    answer: 3,
                                    questionId: 2,
                                    question: question?.name,
                                    point: 0,
                                    message: "",
                                  },
                                  {
                                    answer: 4,
                                    questionId: 2,
                                    question: question?.name,
                                    point: 0,
                                    message: "",
                                  },
                                  {
                                    answer: 5,
                                    questionId: 2,
                                    question: question?.name,
                                    point: 0,
                                    message: "",
                                  },
                                  {
                                    answer: 6,
                                    questionId: 2,
                                    question: question?.name,
                                    point: 0,
                                    message: "",
                                  },
                                  {
                                    answer: 7,
                                    questionId: 2,
                                    question: question?.name,
                                    point: 0,
                                    message: "",
                                  },
                                  {
                                    answer: 8,
                                    questionId: 2,
                                    question: question?.name,
                                    point: 0,
                                    message: "",
                                  },
                                  {
                                    answer: 9,
                                    questionId: 2,
                                    question: question?.name,
                                    point: 0,
                                    message: "",
                                  },
                                  {
                                    answer: 10,
                                    questionId: 2,
                                    question: question?.name,
                                    point: 0,
                                    message: "",
                                  },
                                ]}
                                optionLabel="answer"
                                // virtualScrollerOptions={{ itemSize: 38 }}
                                placeholder="Select Goals"
                                className="w-full md:w-14rem"
                              />
                            ) : question.id == 3 ? (
                              <Dropdown
                                filter
                                value={selectedOption3}
                                onChange={(e) => setSelectedOption3(e.value)}
                                options={[
                                  {
                                    answer: "0",
                                    questionId: 3,
                                    question: question?.name,
                                    point: 0,
                                    message: "",
                                  },
                                  {
                                    answer: 1,
                                    questionId: 3,
                                    question: question?.name,
                                    point: 0,
                                    message: "",
                                  },
                                  {
                                    answer: 2,
                                    questionId: 3,
                                    question: question?.name,
                                    point: 0,
                                    message: "",
                                  },
                                  {
                                    answer: 3,
                                    questionId: 3,
                                    question: question?.name,
                                    point: 0,
                                    message: "",
                                  },
                                  {
                                    answer: 4,
                                    questionId: 3,
                                    question: question?.name,
                                    point: 0,
                                    message: "",
                                  },
                                  {
                                    answer: 5,
                                    questionId: 3,
                                    question: question?.name,
                                    point: 0,
                                    message: "",
                                  },
                                  {
                                    answer: 6,
                                    questionId: 3,
                                    question: question?.name,
                                    point: 0,
                                    message: "",
                                  },
                                  {
                                    answer: 7,
                                    questionId: 3,
                                    question: question?.name,
                                    point: 0,
                                    message: "",
                                  },
                                  {
                                    answer: 8,
                                    questionId: 3,
                                    question: question?.name,
                                    point: 0,
                                    message: "",
                                  },
                                  {
                                    answer: 9,
                                    questionId: 3,
                                    question: question?.name,
                                    point: 0,
                                    message: "",
                                  },
                                  {
                                    answer: 10,
                                    questionId: 3,
                                    question: question?.name,
                                    point: 0,
                                    message: "",
                                  },
                                ]}
                                optionLabel="answer"
                                // virtualScrollerOptions={{ itemSize: 38 }}
                                placeholder="Select Goals"
                                className="w-full md:w-14rem"
                              />
                            ) : question.id == 4 ? (
                              <>
                                <Dropdown
                                  filter
                                  value={selectedOption4}
                                  onChange={(e) => setSelectedOption4(e.value)}
                                  options={[
                                    {
                                      answer: "None",
                                      questionId: 4,
                                      point: 0,
                                      question: question?.name,
                                      playerId: null,
                                      message: "",
                                      playing: false,
                                    },
                                    ...allPlayers.map((data) => {
                                      return {
                                        answer: data?.name,
                                        questionId: 4,
                                        point: 0,
                                        question: question?.name,
                                        playerId: data?.id,
                                        message: "",
                                        playing:
                                          homeTeamLineups.length > 0 &&
                                          awayTeamLineups.length > 0
                                            ? homeTeamLineups[0]?.startXI?.find(
                                                (dataPlayer) =>
                                                  dataPlayer?.player?.id ==
                                                  data?.id
                                              ) ||
                                              awayTeamLineups[0]?.startXI?.find(
                                                (dataPlayer) =>
                                                  dataPlayer?.player?.id ==
                                                  data?.id
                                              )
                                            : false,
                                      };
                                    }),
                                  ]}
                                  virtualScrollerOptions={{ itemSize: 38 }}
                                  optionLabel="answer"
                                  placeholder="Select Player"
                                  className="w-full md:w-14rem"
                                  itemTemplate={itemTemplate}
                                />
                                {selectedOption4?.answer != "None" && (
                                  <Dropdown
                                    filter
                                    value={selectedOption_4}
                                    onChange={(e) =>
                                      setSelectedOption_4(e.value)
                                    }
                                    options={[
                                      {
                                        answer: 1,
                                        questionId: 4,
                                        question: question?.name,
                                        point: 0,
                                        message: "",
                                      },
                                      {
                                        answer: 2,
                                        questionId: 4,
                                        question: question?.name,
                                        point: 0,
                                        message: "",
                                      },
                                      {
                                        answer: 3,
                                        questionId: 4,
                                        question: question?.name,
                                        point: 0,
                                        message: "",
                                      },
                                      {
                                        answer: 4,
                                        questionId: 4,
                                        question: question?.name,
                                        point: 0,
                                        message: "",
                                      },
                                      {
                                        answer: 5,
                                        questionId: 4,
                                        question: question?.name,
                                        point: 0,
                                        message: "",
                                      },
                                      {
                                        answer: 6,
                                        questionId: 4,
                                        question: question?.name,
                                        point: 0,
                                        message: "",
                                      },
                                      {
                                        answer: 7,
                                        questionId: 4,
                                        question: question?.name,
                                        point: 0,
                                        message: "",
                                      },
                                      {
                                        answer: 8,
                                        questionId: 4,
                                        question: question?.name,
                                        point: 0,
                                        message: "",
                                      },
                                      {
                                        answer: 9,
                                        questionId: 4,
                                        question: question?.name,
                                        point: 0,
                                        message: "",
                                      },
                                      {
                                        answer: 10,
                                        questionId: 4,
                                        question: question?.name,
                                        point: 0,
                                        message: "",
                                      },
                                    ]}
                                    optionLabel="answer"
                                    // virtualScrollerOptions={{ itemSize: 38 }}
                                    placeholder="Select Goals"
                                    className="w-full md:w-14rem"
                                  />
                                )}
                              </>
                            ) : question.id == 5 ? (
                              <>
                                <Dropdown
                                  filter
                                  value={selectedOption5}
                                  onChange={(e) => setSelectedOption5(e.value)}
                                  options={[
                                    {
                                      answer: "None",
                                      questionId: 5,
                                      point: 0,
                                      question: question?.name,
                                      playerId: null,
                                      message: "",
                                      playing: false,
                                    },
                                    ...allPlayers.map((data) => {
                                      return {
                                        answer: data?.name,
                                        questionId: 5,
                                        question: question?.name,
                                        point: 0,
                                        playerId: data?.id,
                                        message: "",
                                        playing:
                                          homeTeamLineups.length > 0 &&
                                          awayTeamLineups.length > 0
                                            ? homeTeamLineups[0]?.startXI?.find(
                                                (dataPlayer) =>
                                                  dataPlayer?.player?.id ==
                                                  data?.id
                                              ) ||
                                              awayTeamLineups[0]?.startXI?.find(
                                                (dataPlayer) =>
                                                  dataPlayer?.player?.id ==
                                                  data?.id
                                              )
                                            : false,
                                      };
                                    }),
                                  ]}
                                  virtualScrollerOptions={{ itemSize: 38 }}
                                  optionLabel="answer"
                                  placeholder="Select Player"
                                  className="w-full md:w-14rem"
                                  itemTemplate={itemTemplate}
                                />
                              </>
                            ) : question.id == 6 ? (
                              <>
                                <Dropdown
                                  filter
                                  value={selectedOption6}
                                  onChange={(e) => setSelectedOption6(e.value)}
                                  options={[
                                    {
                                      answer: "None",
                                      questionId: 6,
                                      point: 0,
                                      question: question?.name,
                                      playerId: null,
                                      message: "",
                                      playing: false,
                                    },
                                    ...allPlayers.map((data) => {
                                      return {
                                        answer: data?.name,
                                        questionId: 6,
                                        question: question?.name,
                                        point: 0,
                                        playerId: data?.id,
                                        message: "",
                                        playing:
                                          homeTeamLineups.length > 0 &&
                                          awayTeamLineups.length > 0
                                            ? homeTeamLineups[0]?.startXI?.find(
                                                (dataPlayer) =>
                                                  dataPlayer?.player?.id ==
                                                  data?.id
                                              ) ||
                                              awayTeamLineups[0]?.startXI?.find(
                                                (dataPlayer) =>
                                                  dataPlayer?.player?.id ==
                                                  data?.id
                                              )
                                            : false,
                                      };
                                    }),
                                  ]}
                                  virtualScrollerOptions={{ itemSize: 38 }}
                                  optionLabel="answer"
                                  placeholder="Select Player"
                                  className="w-full md:w-14rem"
                                  itemTemplate={itemTemplate}
                                />
                              </>
                            ) : question.id == 7 ? (
                              <>
                                <Dropdown
                                  filter
                                  value={selectedOption7}
                                  onChange={(e) => setSelectedOption7(e.value)}
                                  options={goalkeeper.map((data) => {
                                    return {
                                      answer: data?.name,
                                      questionId: 7,
                                      question: question?.name,
                                      point: 0,
                                      playerId: data?.id,
                                      message: "",
                                      playing:
                                        homeTeamLineups.length > 0 &&
                                        awayTeamLineups.length > 0
                                          ? homeTeamLineups[0]?.startXI?.find(
                                              (dataPlayer) =>
                                                dataPlayer?.player?.id ==
                                                data?.id
                                            ) ||
                                            awayTeamLineups[0]?.startXI?.find(
                                              (dataPlayer) =>
                                                dataPlayer?.player?.id ==
                                                data?.id
                                            )
                                          : false,
                                    };
                                  })}
                                  virtualScrollerOptions={{ itemSize: 38 }}
                                  optionLabel="answer"
                                  placeholder="Select Player"
                                  className="w-full md:w-14rem"
                                  itemTemplate={itemTemplate}
                                />
                                <Dropdown
                                  filter
                                  value={selectedOption_7}
                                  onChange={(e) => setSelectedOption_7(e.value)}
                                  options={[
                                    {
                                      answer: 1,
                                      questionId: 7,
                                      question: question?.name,
                                      point: 0,
                                      message: "",
                                    },
                                    {
                                      answer: 2,
                                      questionId: 7,
                                      question: question?.name,
                                      point: 0,
                                      message: "",
                                    },
                                    {
                                      answer: 3,
                                      questionId: 7,
                                      question: question?.name,
                                      point: 0,
                                      message: "",
                                    },
                                    {
                                      answer: 4,
                                      questionId: 7,
                                      question: question?.name,
                                      point: 0,
                                      message: "",
                                    },
                                    {
                                      answer: 5,
                                      questionId: 7,
                                      question: question?.name,
                                      point: 0,
                                      message: "",
                                    },
                                    {
                                      answer: 6,
                                      questionId: 7,
                                      question: question?.name,
                                      point: 0,
                                      message: "",
                                    },
                                    {
                                      answer: 7,
                                      questionId: 7,
                                      question: question?.name,
                                      point: 0,
                                      message: "",
                                    },
                                    {
                                      answer: 8,
                                      questionId: 7,
                                      question: question?.name,
                                      point: 0,
                                      message: "",
                                    },
                                    {
                                      answer: 9,
                                      questionId: 7,
                                      question: question?.name,
                                      point: 0,
                                      message: "",
                                    },
                                    {
                                      answer: 10,
                                      questionId: 7,
                                      question: question?.name,
                                      point: 0,
                                      message: "",
                                    },
                                  ]}
                                  optionLabel="answer"
                                  // virtualScrollerOptions={{ itemSize: 38 }}
                                  placeholder="Select Goals"
                                  className="w-full md:w-14rem"
                                />
                              </>
                            ) : question.id == 8 ? (
                              <>
                                <Dropdown
                                  filter
                                  value={selectedOption8}
                                  onChange={(e) => setSelectedOption8(e.value)}
                                  options={goalkeeper.map((data) => {
                                    return {
                                      answer: data?.name,
                                      questionId: 8,
                                      question: question?.name,
                                      point: 0,
                                      playerId: data?.id,
                                      message: "",
                                      playing:
                                        homeTeamLineups.length > 0 &&
                                        awayTeamLineups.length > 0
                                          ? homeTeamLineups[0]?.startXI?.find(
                                              (dataPlayer) =>
                                                dataPlayer?.player?.id ==
                                                data?.id
                                            ) ||
                                            awayTeamLineups[0]?.startXI?.find(
                                              (dataPlayer) =>
                                                dataPlayer?.player?.id ==
                                                data?.id
                                            )
                                          : false,
                                    };
                                  })}
                                  virtualScrollerOptions={{ itemSize: 38 }}
                                  optionLabel="answer"
                                  placeholder="Select Player"
                                  className="w-full md:w-14rem"
                                  itemTemplate={itemTemplate}
                                />
                                <Dropdown
                                  filter
                                  value={selectedOption_8}
                                  onChange={(e) => setSelectedOption_8(e.value)}
                                  options={[
                                    {
                                      answer: 1,
                                      questionId: 8,
                                      question: question?.name,
                                      point: 0,
                                      message: "",
                                    },
                                    {
                                      answer: 2,
                                      questionId: 8,
                                      question: question?.name,
                                      point: 0,
                                      message: "",
                                    },
                                    {
                                      answer: 3,
                                      questionId: 8,
                                      question: question?.name,
                                      point: 0,
                                      message: "",
                                    },
                                    {
                                      answer: 4,
                                      questionId: 8,
                                      question: question?.name,
                                      point: 0,
                                      message: "",
                                    },
                                    {
                                      answer: 5,
                                      questionId: 8,
                                      question: question?.name,
                                      point: 0,
                                      message: "",
                                    },
                                    {
                                      answer: 6,
                                      questionId: 8,
                                      question: question?.name,
                                      point: 0,
                                      message: "",
                                    },
                                    {
                                      answer: 7,
                                      questionId: 8,
                                      question: question?.name,
                                      point: 0,
                                      message: "",
                                    },
                                    {
                                      answer: 8,
                                      questionId: 8,
                                      question: question?.name,
                                      point: 0,
                                      message: "",
                                    },
                                    {
                                      answer: 9,
                                      questionId: 8,
                                      question: question?.name,
                                      point: 0,
                                      message: "",
                                    },
                                    {
                                      answer: 10,
                                      questionId: 8,
                                      question: question?.name,
                                      point: 0,
                                      message: "",
                                    },
                                  ]}
                                  optionLabel="answer"
                                  // virtualScrollerOptions={{ itemSize: 38 }}
                                  placeholder="Select Goals"
                                  className="w-full md:w-14rem"
                                />
                              </>
                            ) : null}
                          </div>
                        );
                      })
                    ) : (
                      <MessageBox>
                        <p className={classes.question_title}>
                          You cannot edit your predictions now as Match has
                          already started or finished!
                        </p>
                      </MessageBox>
                    )}

                    {data?.matchStatus != "Match Finished" &&
                      data?.matchTime == null && (
                        <div className={classes.button_container}>
                          {isUpdating ? (
                            <Button
                              className={classes.next_button}
                              label={
                                lang == "english"
                                  ? "Edit"
                                  : lang == "spanish"
                                  ? "Editar"
                                  : lang == "french"
                                  ? "Modifier"
                                  : lang == "arabic"
                                  ? "يحرر"
                                  : "Edit"
                              }
                              severity="danger"
                              raised
                              onClick={() =>
                                saveUserPrediction({ chargeUser: false })
                              }
                            />
                          ) : (
                            <Button
                              className={classes.next_button}
                              label={
                                lang == "english"
                                  ? "Submit"
                                  : lang == "spanish"
                                  ? "Entregar"
                                  : lang == "french"
                                  ? "Soumettre"
                                  : lang == "arabic"
                                  ? "يُقدِّم"
                                  : "Submit"
                              }
                              severity="danger"
                              raised
                              onClick={() =>
                                saveUserPrediction({ chargeUser: true })
                              }
                            />
                          )}
                        </div>
                      )}
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </Layout>
  );
};

export default PredictMatchPage;
