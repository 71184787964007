import React, { useEffect, useState } from "react";
import classes from "../css/LoginPage.module.css";
import Lottie from "lottie-react";
import Logo from "../animations/logo.json";
import { toast } from "react-toastify";
import { loginApi, subscriptionApi } from "../api/api";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { encryptNumber } from "../utils/helper";
import { v4 as uuidv4 } from "uuid";

const HomePage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [extRef, setExtRef] = useState(null);
  const [loading, setLoading] = useState(false);
  const [actionType, setActionType] = useState("subscribe");
  useEffect(() => {
    // console.log(searchParams.get("ext_ref"),'searchParams...');
    if (searchParams.get("ext_ref")) {
      setExtRef(searchParams.get("ext_ref"));
    } else {
      // auto generate ext_ref
      // setExtRef(uuidv4());
      console.log("auto generate ext_ref...");
      setSearchParams({ ext_ref: uuidv4() });
    }

    const style = document.createElement("style");
    style.innerHTML = `
      *, *::before, *::after {
        margin:0;
        padding:0;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, [searchParams]);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [currencies, setCurrencies] = useState([
    {
      label: "Zimbabwe (ZWG)",
      value: "ZIG",
    },
    {
      label: "Zimbabwe (USD)",
      value: "USD",
    },
  ]);

  const [packs, setPacks] = useState({
    ZIG: [
      {
        packName: "Daily",
        packLabel: "Daily (2.02) ZWG",
        value: "Daily",
      },
      {
        packName: "Weekly",
        packLabel: "Weekly (8.08) ZWG",
        value: "Weekly",
      },
      {
        packName: "Monthly",
        packLabel: "Monthly (24.25) ZWG",
        value: "Monthly",
      },
      {
        packName: "Predict N Win",
        packLabel: "Predict N Win (3.5) ZWG",
        value: "Predict N Win",
      },
    ],
    USD: [
      {
        packName: "Daily",
        packLabel: "Daily (0.03) USD",
        value: "Daily",
      },
      {
        packName: "Weekly",
        packLabel: "Weekly (0.1) USD",
        value: "Weekly",
      },
      {
        packName: "Monthly",
        packLabel: "Monthly (0.3) USD",
        value: "Monthly",
      },
      {
        packName: "Predict N Win",
        packLabel: "Predict N Win (0.1) USD",
        value: "Predict N Win",
      },
    ],
  });

  const [selectedPack, setSelectedPack] = useState(null);
  const [number, setNumber] = useState("");
  const [formType, setFormType] = useState("currency");
  const navigate = useNavigate();
  const handleNext = () => {
    if (number.trim().length <= 0) {
      toast.error("Please Enter the Number!");
    } else if (!selectedCurrency) {
      toast.error("Please Select the Currency!");
    } else {
      setFormType("pack");
    }
  };

  const subsriptionHandler = async (e) => {
    e.preventDefault();
    if (!selectedPack) {
      toast.error("Please Select the Pack!");
      return;
    }
    try {
      setLoading(true);
      const response = await axios.post(subscriptionApi, {
        msisdn: number,
        pack_type: selectedPack,
        ext_ref: extRef,
        currency: selectedCurrency,
      });
      console.log(response, "response....");

      setLoading(false);
      toast.success("You have Successfully Subscribed to the Kickwinn!");
      setTimeout(() => {
        navigateToPortal(number);
      }, 1000);
    } catch (error) {
      setLoading(false);

      console.log(error, "error---");
      toast.error(error?.response?.data?.message || error?.data?.message);
    }
  };

  const loginHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await axios.post(loginApi, {
        msisdn: number,
      });
      setLoading(false);

      console.log(response, "response....");

      setTimeout(() => {
        navigateToPortal(number);
      }, 1000);

      // navigate the user to portal .. and login him...
    } catch (error) {
      setLoading(false);

      console.log(error);
      toast.error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.data?.err ||
          error?.data?.message
      );
    }
  };

  const navigateToPortal = (msisdn) => {
    const encryptedNumber = encryptNumber(msisdn);
    navigate(`/authenticate?token=${encryptedNumber}`);
  };

  return (
    <main className={classes.main}>
      <nav className={classes.navbar}>
        <div className={classes.logo_container}>
          <div className={classes.logo_image_container}>
            <Lottie
              animationData={Logo}
              loop={true}
              autoplay={true}
              className={classes.logo}
            />
          </div>
          <h1 className={classes.logo_text}>Kickwinn</h1>
        </div>

        {/* <div className={classes.navigation_links}>
          <button type="button" className={classes.navigation_button}>
            Subscribe
          </button>
          <button type="button" className={classes.navigation_button}>
            Features
          </button>
          <button type="button" className={classes.navigation_button}>
            How it works
          </button>
        </div>

        <button type="button" className={classes.navigation_button}>
          Service
        </button> */}
      </nav>

      <section className={classes.section_landing_page}>
        <section className={classes.section_subscribe}>
          <div className={classes.landing_page_heading_container}>
            <h2 className={classes.landing_page_heading}>Kickwinn</h2>
            <p className={classes.landing_page_paragraph}>
              Subscribe Now to Play Football Fantasy Game, Join Match Contests,
              Predict and Win etc!
            </p>
          </div>

          {/* {showInfo ? 
          <div className={classes.subscription_form}>
               <div className={classes.form_container}>
            <h1 className={classes.subscribed_title}>You have successfully subscribed to Kickwinn!</h1>
            <button
                            className={classes.next_button}
                            type="button"
                            onClick={()=>null}
                          >
                            Play Fantasy Games
                          </button>
               </div>

          </div>
          :  */}
          <form className={classes.subscription_form}>
            <div className={classes.form_container}>
              <div className={classes.grid_menu}>
                <button
                  type="button"
                  className={`${classes.grid_menu_item_1} ${
                    actionType === "subscribe" && classes.active
                  }`}
                  onClick={() => setActionType("subscribe")}
                >
                  <p
                    className={`${classes.grid_menu_text} ${
                      actionType === "subscribe" && classes.active
                    }`}
                  >
                    Subscribe
                  </p>
                </button>
                <button
                  type="button"
                  className={`${classes.grid_menu_item_2} ${
                    actionType === "login" && classes.active
                  }`}
                  onClick={() => setActionType("login")}
                >
                  <p
                    className={`${classes.grid_menu_text} ${
                      actionType === "login" && classes.active
                    }`}
                  >
                    Login
                  </p>
                </button>
              </div>

              <div className={classes.input_field_grid}>
                <div className={classes.country_code_field}>
                  <img
                    src="/assets/images/zimbabwe.png"
                    className={classes.country_flag}
                    alt="zimbabwe"
                  />
                  <p className={classes.country_code}>+236</p>
                </div>
                <input
                  type="number"
                  autoFocus
                  placeholder="Enter Your Number"
                  className={classes.number_input_field}
                  onKeyDown={(e) => {
                    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                      e.preventDefault();
                    }
                  }}
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                />
              </div>

              {actionType === "login" ? (
                <button
                  className={classes.next_button}
                  type="button"
                  onClick={loginHandler}
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Login"}
                </button>
              ) : formType === "currency" ? (
                <>
                  <div className={classes.currency_selector_heading_container}>
                    <p>Choose Currency</p>
                  </div>
                  <div className={classes.currency_buttons}>
                    {currencies.map((currency, index) => {
                      return (
                        <button
                          className={`${classes.currency_button} ${
                            selectedCurrency === currency.value &&
                            classes.active_currency
                          }`}
                          type="button"
                          key={index}
                          onClick={() => setSelectedCurrency(currency.value)}
                        >
                          {currency.label}
                          {selectedCurrency === currency.value && (
                            <div className={classes.selected_icon}>
                              <i
                                className={`fa-solid fa-check  ${classes.icon}`}
                              ></i>
                            </div>
                          )}
                        </button>
                      );
                    })}
                  </div>

                  <button
                    className={classes.next_button}
                    type="button"
                    onClick={handleNext}
                  >
                    Next
                  </button>
                </>
              ) : formType === "pack" ? (
                <>
                  <div className={classes.currency_selector_heading_container}>
                    <button
                      className={classes.back_button}
                      type="button"
                      onClick={() => {
                        setFormType("currency");
                        setSelectedPack(null);
                      }}
                    >
                      <i
                        className={`fa-solid fa-arrow-left ${classes.back_icon}`}
                      ></i>
                    </button>
                    <p>Choose Pack</p>
                  </div>

                  <div className={classes.currency_buttons}>
                    {packs[selectedCurrency]?.map((pack, index) => {
                      return (
                        <button
                          className={`${classes.currency_button} ${
                            selectedPack === pack.value &&
                            classes.active_currency
                          }`}
                          type="button"
                          key={index}
                          onClick={() => setSelectedPack(pack.value)}
                        >
                          {pack.packLabel}
                          {selectedPack === pack.value && (
                            <div className={classes.selected_icon}>
                              <i
                                className={`fa-solid fa-check  ${classes.icon}`}
                              ></i>
                            </div>
                          )}
                        </button>
                      );
                    })}
                  </div>

                  <button
                    className={classes.next_button}
                    type="button"
                    onClick={subsriptionHandler}
                    disabled={loading}
                  >
                    {loading ? "Loading..." : "Subscribe"}
                  </button>
                </>
              ) : null}
            </div>
          </form>
          {/* } */}
        </section>
        <section className={classes.section_landing_page_image}>
          <img
            src="/assets/images/landing-page-big-image.png"
            alt="soccer-image"
            className={classes.landing_page_soccer_image}
          />
        </section>
      </section>

      <section className={classes.section_contest}>
        <div className={classes.contest_image_container}>
          <img
            src="/assets/images/contest-image2.png"
            alt="contest image"
            className={classes.contest_image}
          />
        </div>
        <section className={classes.contest_page_content}>
          <h2 className={classes.contest_heading}>
            ⚽ Join Kickwinn's Football Match Contests!
          </h2>
          <ul className={classes.list_ul}>
            <li>
              <span>🏆 Create Your Fantasy Team - </span>
              Build your dream squad by selecting real players and compete in
              fantasy leagues.
            </li>
            <li>
              <span>🔮 Predict and Win – </span>
              Analyze matches, predict scores, and win exciting rewards.
            </li>
            <li>
              <span>⚔️ Compete with Others – </span>
              Challenge football fans worldwide and climb the leaderboard.
            </li>
            <li>
              <span>📡 Live Match Engagement – </span>
              Stay updated with live games and make strategic decisions.
            </li>
          </ul>
        </section>
      </section>

      <section className={classes.section_prizes}>
        <section className={classes.prize_content}>
          <h2 className={classes.prize_heading}>🏆 Win Exciting Prizes!</h2>
          <ul className={classes.prize_list_ul}>
            <li>
              <span>🎁 Cash Rewards – </span>
              Win real money by participating in fantasy contests and match
              predictions.
            </li>
            <li>
              <span>🏅 Leaderboard Rankings - </span>
              Compete with top players and secure a spot on the leaderboard for
              exclusive prizes.
            </li>
            <li>
              <span>📱 Recharge Coupons & Gift Cards – </span>
              Win mobile recharge coupons, and more exciting rewards!
            </li>
          </ul>
        </section>

        <div className={classes.prize_image_container}>
          <img
            src="/assets/images/prizes.png"
            alt="contest image"
            className={classes.prize_image}
          />
        </div>
      </section>

      <footer className={classes.footer}>
        <p className={classes.copyright_mark}>
          © {new Date().getFullYear()} Digitize Consultant. All rights reserved.
        </p>
      </footer>
    </main>
  );
};

export default HomePage;
