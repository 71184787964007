import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import Drawer from "../components/Drawer";
import MenuTabs from "../components/MenuTabs";
import Loader from "../components/Loader";
import classes from "../css/CreateTeamPage.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  baseUrl,
  createUserTeamApi,
  fetchMatchContestByIdApi,
  fetchPlayersApi,
  getUserTeamApi,
  matchByIdApi,
  teamLineupsApi,
} from "../api/api";
import axios from "axios";
import { toast } from "react-toastify";
import { resetTeam, setTeam } from "../slices/userTeamSlice";
import ContestLayout from "../components/ContestLayout";
import { TabPanel, TabView } from "primereact/tabview";
import PlayerList from "../components/PlayerList";
import { Button } from "primereact/button";
import PlayersModal from "../components/PlayersModal";
import HeadingPrimary from "../components/Headings/HeadingPrimary";

const CreateTeamPage = () => {
  const [loading, setLoading] = useState(false);

  const { id, homeTeamId, awayTeamId, contestId } = useParams();
  const [data, setData] = useState([]);
  const [matchContest, setMatchContest] = useState(null);
  const [players, setPlayers] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [attackers, setAttackers] = useState([]);
  const [defenders, setDefenders] = useState([]);
  const [midfielders, setMidfielders] = useState([]);
  const [goalkeeper, setGoalkeeper] = useState([]);
  const [homeTeamLineups, setHomeTeamLineups] = useState([]);
  const [awayTeamLineups, setAwayTeamLineups] = useState([]);

  const [open, setOpen] = useState(false);
  const [captain, setCaptain] = useState([]);
  const [viceCaptain, setViceCaptain] = useState([]);

  const { user } = useSelector((state) => state.userSlice);
  const { team } = useSelector((state) => state.userTeamSlice);

  const { lang } = useSelector((state) => state.langSlice);

  const fetchDataFromBackend = async (loadDataWithLoading) => {
    try {
      if (loadDataWithLoading?.loading) {
        setLoading(true);
      }
      const res = await axios.get(`${baseUrl}${matchByIdApi}${id}`);
      setData(res?.data?.message[0]);
    } catch (error) {
      // console.log(error, "error");
      setLoading(false);
      toast.error(
        error?.data || error?.data?.message || "Something Went Wrong!"
      );
    }
  };

  const fetchPlayersFromBackend = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}${fetchPlayersApi}/${homeTeamId}/${awayTeamId}`,
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      setPlayers(response?.data?.teamData);
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  const { active, name } = useSelector((state) => state.navbarPlayersSlice);

  const fetchUserTeam = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}${getUserTeamApi}`,
        {
          userId: user?.id,
          match_fixture_id: id,
          contest_id: contestId,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      if (response?.data?.status == 1) {
        dispatch(setTeam(JSON.parse(response?.data?.result[0]?.team)));
      } else {
        dispatch(resetTeam());
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  const fetchMatchContest = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}${fetchMatchContestByIdApi}`,
        {
          contestId: contestId,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      console.log(response, "response");
      setMatchContest(response?.data?.result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  const fetchHomeTeamLineups = async () => {
    try {
      const res = await axios.get(
        `${baseUrl}${teamLineupsApi}/${id}/${homeTeamId}`
      );

      setHomeTeamLineups(res?.data?.response);
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.data || error?.data?.message || "Something Went Wrong!"
      );
    }
  };
  const fetchAwayTeamLineups = async () => {
    try {
      const res = await axios.get(
        `${baseUrl}${teamLineupsApi}/${id}/${awayTeamId}`
      );
      setAwayTeamLineups(res?.data?.response);
    } catch (error) {
      // console.log(error, "error");
      setLoading(false);
      toast.error(
        error?.data || error?.data?.message || "Something Went Wrong!"
      );
    }
  };

  useEffect(() => {
    fetchDataFromBackend({ loading: true });
    fetchHomeTeamLineups();
    fetchAwayTeamLineups();
    fetchPlayersFromBackend();
    fetchUserTeam();
    fetchMatchContest();
    const intervalId = setInterval(() => {
      if (!document.hidden) {
        fetchDataFromBackend({ loading: false });
      }
    }, 30000);
    return () => clearInterval(intervalId);
  }, []);

  const createTeamHandler = async (path) => {
    if (captain.length !== 1 || viceCaptain.length !== 1) {
      toast.info(
        lang == "english"
          ? "Please Select Captain and Vice Captain!"
          : lang == "spanish"
          ? "¡Seleccione Capitán y Vicecapitán!"
          : lang == "french"
          ? "Veuillez sélectionner le capitaine et le vice-capitaine !"
          : lang == "arabic"
          ? "الرجاء اختيار الكابتن ونائب الكابتن!"
          : "Please Select Captain and Vice Captain!"
      );
      return;
    }
    try {
      const dataToSend = {
        userId: user?.id,
        userNumber: user?.user_number,
        match_fixture_id: id,
        team: team?.map((data) => {
          return {
            ...data,
            point: 0,
          };
        }),
        home_team_name: data?.homeTeam,
        away_team_name: data?.awayTeam,
        home_team_logo: data?.homeLogo,
        away_team_logo: data?.awayLogo,
        home_team_id: homeTeamId,
        away_team_id: awayTeamId,
        match_date_time: data?.time,
        total_points: 0,
        contest_id: contestId,
        operator: user?.operator,
      };

      const response = await axios.post(
        `${baseUrl}${createUserTeamApi}`,
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      console.log(response, "response");
      navigate(path);
    } catch (error) {
      console.log(error);
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  useEffect(() => {
    setAttackers(() => {
      return team.filter((dataItem) => dataItem?.position == "Attacker");
    });
    setDefenders(() => {
      return team.filter((dataItem) => dataItem?.position == "Defender");
    });
    setMidfielders(() => {
      return team.filter((dataItem) => dataItem?.position == "Midfielder");
    });
    setGoalkeeper(() => {
      return team.filter((dataItem) => dataItem?.position == "Goalkeeper");
    });
    setCaptain(() => {
      return team.filter((data) => data.captain == true);
    });

    setViceCaptain(() => {
      return team.filter((data) => data.viceCaptain == true);
    });
  }, [team]);

  return (
    <Layout active={4} hideMenu={true}>
      <Drawer active={4} />
      <div className={classes.container}>
        <MenuTabs />

        {loading ? (
          <Loader />
        ) : (
          <>
            {/* <div className={classes.match_container}>
              <ContestLayout match={data} />
            </div> */}

            <HeadingPrimary
              title={
                lang == "english"
                  ? "Create a Fantasy Team"
                  : lang == "spanish"
                  ? "Crea un equipo de fantasía"
                  : lang == "french"
                  ? "Créer une équipe fantastique"
                  : lang == "arabic"
                  ? "إنشاء فريق الخيال"
                  : "Create a Fantasy Team"
              }
            />

            {team.length > 10 &&
              data?.matchStatus != "Match Finished" &&
              data?.matchTime == null && (
                <div className={classes.button_container}>
                  <Button
                    className={classes.next_button}
                    label={
                      lang == "english"
                        ? "Next"
                        : lang == "spanish"
                        ? "Próximo"
                        : lang == "french"
                        ? "Suivant"
                        : lang == "arabic"
                        ? "التالي"
                        : "Next"
                    }
                    severity="danger"
                    onClick={() => {
                      setOpen(true);
                    }}
                    raised
                  />
                </div>
              )}

            <div className={classes.tab_container}>
              <TabView scrollable>
                <TabPanel
                  header={`
                ${
                  lang == "english"
                    ? "GoalKeepers"
                    : lang == "spanish"
                    ? "Porteros"
                    : lang == "french"
                    ? "Gardiens de but"
                    : lang == "arabic"
                    ? "حراس المرمى"
                    : "GoalKeepers"
                }
                 ${goalkeeper?.length}`}
                >
                  <div className={classes.list_container}>
                    <div className={classes.list_sub_container}>
                      {players[0]?.goalkeepers?.map((dataItem) => {
                        return (
                          <PlayerList
                            dataItem={dataItem}
                            teamName={players[0]?.teamName}
                            attackers={attackers}
                            defenders={defenders}
                            midfielders={midfielders}
                            goalkeeper={goalkeeper}
                            homeTeamLineups={homeTeamLineups}
                            awayTeamLineups={awayTeamLineups}
                          />
                        );
                      })}
                      {players[1]?.goalkeepers?.map((dataItem) => {
                        return (
                          <PlayerList
                            dataItem={dataItem}
                            teamName={players[1]?.teamName}
                            attackers={attackers}
                            defenders={defenders}
                            midfielders={midfielders}
                            goalkeeper={goalkeeper}
                            homeTeamLineups={homeTeamLineups}
                            awayTeamLineups={awayTeamLineups}
                          />
                        );
                      })}
                    </div>
                  </div>
                </TabPanel>
                <TabPanel
                  header={`
                 ${
                   lang == "english"
                     ? "Defenders"
                     : lang == "spanish"
                     ? "Defensores"
                     : lang == "french"
                     ? "Défenseurs"
                     : lang == "arabic"
                     ? "المدافعون"
                     : "Defenders"
                 }
                 ${defenders?.length}`}
                >
                  <div className={classes.list_container}>
                    <div className={classes.list_sub_container}>
                      {players[0]?.defenders?.map((dataItem) => {
                        return (
                          <PlayerList
                            dataItem={dataItem}
                            teamName={players[0]?.teamName}
                            attackers={attackers}
                            defenders={defenders}
                            midfielders={midfielders}
                            goalkeeper={goalkeeper}
                            homeTeamLineups={homeTeamLineups}
                            awayTeamLineups={awayTeamLineups}
                          />
                        );
                      })}
                      {players[1]?.defenders?.map((dataItem) => {
                        return (
                          <PlayerList
                            dataItem={dataItem}
                            teamName={players[1]?.teamName}
                            attackers={attackers}
                            defenders={defenders}
                            midfielders={midfielders}
                            goalkeeper={goalkeeper}
                            homeTeamLineups={homeTeamLineups}
                            awayTeamLineups={awayTeamLineups}
                          />
                        );
                      })}
                    </div>
                  </div>
                </TabPanel>
                <TabPanel
                  header={`
                 ${
                   lang == "english"
                     ? "Midfielders"
                     : lang == "spanish"
                     ? "Centrocampistas"
                     : lang == "french"
                     ? "Milieu de terrain"
                     : lang == "arabic"
                     ? "لاعبو خط الوسط"
                     : "Midfielders"
                 }
                ${midfielders?.length}`}
                >
                  <div className={classes.list_container}>
                    <div className={classes.list_sub_container}>
                      {players[0]?.midfielders?.map((dataItem) => {
                        return (
                          <PlayerList
                            dataItem={dataItem}
                            teamName={players[0]?.teamName}
                            attackers={attackers}
                            defenders={defenders}
                            midfielders={midfielders}
                            goalkeeper={goalkeeper}
                            homeTeamLineups={homeTeamLineups}
                            awayTeamLineups={awayTeamLineups}
                          />
                        );
                      })}
                      {players[1]?.midfielders?.map((dataItem) => {
                        return (
                          <PlayerList
                            dataItem={dataItem}
                            teamName={players[1]?.teamName}
                            attackers={attackers}
                            defenders={defenders}
                            midfielders={midfielders}
                            goalkeeper={goalkeeper}
                            homeTeamLineups={homeTeamLineups}
                            awayTeamLineups={awayTeamLineups}
                          />
                        );
                      })}
                    </div>
                  </div>
                </TabPanel>
                <TabPanel
                  header={`
                   ${
                     lang == "english"
                       ? "Attackers"
                       : lang == "spanish"
                       ? "Atacantes"
                       : lang == "french"
                       ? "Attaquants"
                       : lang == "arabic"
                       ? "المهاجمين"
                       : "Attackers"
                   }
                 ${attackers?.length}`}
                >
                  <div className={classes.list_container}>
                    <div className={classes.list_sub_container}>
                      {players[0]?.attackers?.map((dataItem) => {
                        return (
                          <PlayerList
                            dataItem={dataItem}
                            teamName={players[0]?.teamName}
                            attackers={attackers}
                            defenders={defenders}
                            midfielders={midfielders}
                            goalkeeper={goalkeeper}
                            homeTeamLineups={homeTeamLineups}
                            awayTeamLineups={awayTeamLineups}
                          />
                        );
                      })}
                      {players[1]?.attackers?.map((dataItem) => {
                        return (
                          <PlayerList
                            dataItem={dataItem}
                            teamName={players[1]?.teamName}
                            attackers={attackers}
                            defenders={defenders}
                            midfielders={midfielders}
                            goalkeeper={goalkeeper}
                            homeTeamLineups={homeTeamLineups}
                            awayTeamLineups={awayTeamLineups}
                          />
                        );
                      })}
                    </div>
                  </div>
                </TabPanel>
              </TabView>
            </div>
          </>
        )}
      </div>
      <PlayersModal
        open={open}
        close={() => setOpen(false)}
        submitHandler={() => {
          createTeamHandler(
            `/match/created-team/${id}/${homeTeamId}/${awayTeamId}/${contestId}`
          );
        }}
        captain={captain}
        viceCaptain={viceCaptain}
        homeTeamLineups={homeTeamLineups}
        awayTeamLineups={awayTeamLineups}
      />
    </Layout>
  );
};

export default CreateTeamPage;
