import axios from "axios";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { baseUrl, checkOtherPlayersTeamApi } from "../api/api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import classes from "../css/CreatedTeamPage.module.css";
import CircularLoading from "./loaders/CircularLoading";
import { Fieldset } from "primereact/fieldset";

const PlayerTeamModal = ({ modal, setModal }) => {
  const { user } = useSelector((state) => state?.userSlice);
  const { lang } = useSelector((state) => state?.langSlice);
  const [attackers, setAttackers] = useState([]);
  const [midfielders, setMidfielders] = useState([]);
  const [defenders, setDefenders] = useState([]);
  const [goalkeeper, setGoalkeeper] = useState([]);
  const [totalPoints, setTotalPoints] = useState(null);
  const [loading, setLoading] = useState(true);
  const fetchDataFromBackend = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${baseUrl}${checkOtherPlayersTeamApi}`,
        {
          user_id: modal?.user_id,
          contest_id: modal?.contest_id,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      let data = JSON.parse(response?.data?.result[0]?.team);
      setTotalPoints(response?.data?.result[0]?.total_points);
      setAttackers(() => {
        return data.filter((dataItem) => dataItem?.position == "Attacker");
      });
      setDefenders(() => {
        return data.filter((dataItem) => dataItem?.position == "Defender");
      });
      setMidfielders(() => {
        return data.filter((dataItem) => dataItem?.position == "Midfielder");
      });
      setGoalkeeper(() => {
        return data.filter((dataItem) => dataItem?.position == "Goalkeeper");
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  useEffect(() => {
    if (modal?.modal) {
      fetchDataFromBackend();
    }
  }, [modal]);
  return (
    <Dialog
      header={
        lang == "english"
          ? `${modal?.user_name} Team Overview`
          : lang == "spanish"
          ? `${modal?.user_name} Descripción general del equipo`
          : lang == "french"
          ? `${modal?.user_name} Aperçu de l'équipe`
          : lang == "arabic"
          ? `نظرة عامة على الفريق${modal?.user_name}`
          : `${modal?.user_name} Team Overview`
      }
      visible={modal?.modal}
      maximizable
      style={{ width: "100vw" }}
      onHide={() =>
        setModal({
          modal: false,
          user_id: null,
          contest_id: null,
        })
      }
    >
      {loading ? (
        <CircularLoading />
      ) : (
        <>
          <div className={classes.line_up_container}>
            <div className={classes.line_up_sub_container}>
              <img
                src="/assets/images/football_ground_half.jpg"
                alt="football ground"
                className={classes.ground}
              />

              <div className={classes.sub_container}>
                <div
                  className={classes.grid_container}
                  style={{
                    gridTemplateColumns: `repeat(${attackers.length}, 1fr)`,
                  }}
                >
                  {attackers.map((data) => {
                    return (
                      <div className={classes.grid_sub_container}>
                        <div className={classes.image_container}>
                          <img
                            src={data?.photo}
                            alt=""
                            className={classes.image}
                          />
                          <div className={classes.square}>
                            <p className={classes.point}>{data?.point}</p>
                          </div>
                          {data?.captain ? (
                            <div className={classes.square2}>
                              <p className={classes.indicate}>C</p>
                            </div>
                          ) : data?.viceCaptain ? (
                            <div className={classes.square2}>
                              <p className={classes.indicate}>VC</p>
                            </div>
                          ) : null}
                        </div>
                        <div className={classes.player_name}>
                          <p className={classes.text}>{data?.name}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div
                  className={classes.grid_container}
                  style={{
                    gridTemplateColumns: `repeat(${midfielders.length}, 1fr)`,
                  }}
                >
                  {midfielders.map((data) => {
                    return (
                      <div className={classes.grid_sub_container}>
                        <div className={classes.image_container}>
                          <img
                            src={data?.photo}
                            alt=""
                            className={classes.image}
                          />
                          <div className={classes.square}>
                            <p>{data?.point}</p>
                          </div>
                          {data?.captain ? (
                            <div className={classes.square2}>
                              <p>C</p>
                            </div>
                          ) : data?.viceCaptain ? (
                            <div className={classes.square2}>
                              <p>VC</p>
                            </div>
                          ) : null}
                        </div>
                        <div className={classes.player_name}>
                          <p>{data?.name}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div
                  className={classes.grid_container}
                  style={{
                    gridTemplateColumns: `repeat(${defenders.length}, 1fr)`,
                  }}
                >
                  {defenders.map((data) => {
                    return (
                      <div className={classes.grid_sub_container}>
                        <div className={classes.image_container}>
                          <img
                            src={data?.photo}
                            alt=""
                            className={classes.image}
                          />
                          <div className={classes.square}>
                            <p>{data?.point}</p>
                          </div>
                          {data?.captain ? (
                            <div className={classes.square2}>
                              <p>C</p>
                            </div>
                          ) : data?.viceCaptain ? (
                            <div className={classes.square2}>
                              <p>VC</p>
                            </div>
                          ) : null}
                        </div>
                        <div className={classes.player_name}>
                          <p>{data?.name}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div
                  className={classes.grid_container}
                  style={{
                    gridTemplateColumns: `repeat(${goalkeeper.length}, 1fr)`,
                  }}
                >
                  {goalkeeper.map((data) => {
                    return (
                      <div className={classes.grid_sub_container}>
                        <div className={classes.image_container}>
                          <img
                            src={data?.photo}
                            alt=""
                            className={classes.image}
                          />
                          <div className={classes.square}>
                            <p>{data?.point}</p>
                          </div>
                          {data?.captain ? (
                            <div className={classes.square2}>
                              <p>C</p>
                            </div>
                          ) : data?.viceCaptain ? (
                            <div className={classes.square2}>
                              <p>VC</p>
                            </div>
                          ) : null}
                        </div>
                        <div className={classes.player_name}>
                          <p>{data?.name}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.btn_container}>
            <Fieldset
              style={{ width: "200px" }}
              legend={
                lang == "english"
                  ? `${modal?.user_name} Points`
                  : lang == "spanish"
                  ? `${modal?.user_name} puntos`
                  : lang == "french"
                  ? `${modal?.user_name} points `
                  : lang == "arabic"
                  ? `نقاط${modal?.user_name}`
                  : `${modal?.user_name} Points`
              }
            >
              <p className={classes.points}>
                {lang == "english"
                  ? `${modal?.user_name} Points`
                  : lang == "spanish"
                  ? `${modal?.user_name} puntos`
                  : lang == "french"
                  ? `${modal?.user_name} points `
                  : lang == "arabic"
                  ? `نقاط${modal?.user_name}`
                  : `${modal?.user_name} Points`}{" "}
                : {totalPoints}
              </p>
            </Fieldset>
          </div>
        </>
      )}
    </Dialog>
  );
};

export default PlayerTeamModal;
