import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addCaptain,
  addViceCaptain,
  removeCaptain,
  removeViceCaptain,
} from "../slices/userTeamSlice";
import { toast } from "react-toastify";
import { Dialog } from "primereact/dialog";
import classes from "./PlayersModal.module.css";
import { IconButton } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "primereact/button";

const PlayersModal = ({
  open,
  close,
  submitHandler,
  captain,
  viceCaptain,
  homeTeamLineups,
  awayTeamLineups,
}) => {
  const { team } = useSelector((state) => state.userTeamSlice);
  const { lang } = useSelector((state) => state.langSlice);

  const dispatch = useDispatch();

  const AddViceCaptain = (data) => {
    if (viceCaptain.length !== 0) {
      toast.info(
        lang == "english"
          ? "ViceCaptain Already Selected."
          : lang == "spanish"
          ? "Vicecapitán ya seleccionado."
          : lang == "french"
          ? "ViceCaptain déjà sélectionné."
          : lang == "arabic"
          ? "تم تحديد ViceCaptain بالفعل."
          : "ViceCaptain Already Selected."
      );
    } else if (data.captain == true) {
      toast.info(
        lang == "english"
          ? "This Player is Captain."
          : lang == "spanish"
          ? "Este jugador es el capitán."
          : lang == "french"
          ? "Ce joueur est le capitaine."
          : lang == "arabic"
          ? "هذا اللاعب هو الكابتن"
          : "This Player is Captain."
      );
    } else if (viceCaptain.length == 0 && data.captain !== true) {
      dispatch(addViceCaptain(data?.id));
    }
  };

  const AddCaptain = (data) => {
    if (captain.length !== 0) {
      toast.info(
        lang == "english"
          ? "Captain Already Selected."
          : lang == "spanish"
          ? "Capitán ya seleccionado."
          : lang == "french"
          ? "Capitaine déjà sélectionné."
          : lang == "arabic"
          ? "تم اختيار الكابتن بالفعل."
          : "Captain Already Selected."
      );
    } else if (data.viceCaptain == true) {
      toast.info(
        lang == "english"
          ? "This Player is viceCaptain."
          : lang == "spanish"
          ? "Este jugador es vice capitán."
          : lang == "french"
          ? "Ce joueur est vice-capitaine."
          : lang == "arabic"
          ? "هذا اللاعب هو نائب الكابتن."
          : "This Player is viceCaptain."
      );
    } else if (captain.length == 0 && data.viceCaptain !== true) {
      dispatch(addCaptain(data?.id));
    }
  };

  return (
    <Dialog
      header={
        lang == "english"
          ? "Select Captain and Vice Captain"
          : lang == "spanish"
          ? "Seleccionar capitán y vice capitán"
          : lang == "french"
          ? "Sélectionnez le capitaine et le vice-capitaine"
          : lang == "arabic"
          ? "اختر الكابتن ونائب الكابتن"
          : "Select Captain and Vice Captain"
      }
      visible={open}
      maximizable
      style={{ width: "90%", height: "100dvh" }}
      onHide={close}
    >
      <div className={classes.team_container}>
        <Button
          label={
            lang == "english"
              ? "Join"
              : lang == "spanish"
              ? "Unirse"
              : lang == "french"
              ? "Rejoindre"
              : lang == "arabic"
              ? "ينضم"
              : "Join"
          }
          className={classes.btn}
          onClick={submitHandler}
          severity="danger"
          raised
        />

        <div className={classes.team_sub_container}>
          <div className={`${classes.team2} ${classes.fixed}`}>
            <div className={classes.header_1}>
              <p className={classes.text}>
                {lang == "english"
                  ? "Player"
                  : lang == "spanish"
                  ? "Jugador"
                  : lang == "french"
                  ? "Joueur"
                  : lang == "arabic"
                  ? "لاعب"
                  : "Player"}
              </p>
            </div>
            <div className={classes.header_2} style={{ marginLeft: "10px" }}>
              <p className={classes.text}>
                {lang == "english"
                  ? "VC"
                  : lang == "spanish"
                  ? "VC"
                  : lang == "french"
                  ? "Capital-risque"
                  : lang == "arabic"
                  ? "VC"
                  : "VC"}
              </p>
            </div>
            <div className={classes.header_3}>
              <p className={classes.text}>
                {lang == "english"
                  ? "C"
                  : lang == "spanish"
                  ? "C"
                  : lang == "french"
                  ? "C"
                  : lang == "arabic"
                  ? "ج"
                  : "C"}
              </p>
            </div>
          </div>

          <div
            className={`${classes.underline} ${classes.underline_fixed}`}
          ></div>

          {team?.map((dataItem, idx) => (
            <>
              <div
                key={dataItem?.id}
                className={`${classes.team} ${idx == 0 && classes.first}`}
              >
                <div className={classes.player_container}>
                  <div className={classes.player_image}>
                    <img
                      src={dataItem?.photo}
                      alt={dataItem?.name}
                      className={classes.img}
                    />
                  </div>
                  <div className={classes.player_name}>
                    <p className={classes.text}>{dataItem?.name}</p>
                    <p className={classes.text}>({dataItem?.teamName})</p>
                    {(homeTeamLineups[0]?.startXI?.find(
                      (data) => data?.player?.id == dataItem?.id
                    ) ||
                      awayTeamLineups[0]?.startXI?.find(
                        (data) => data?.player?.id == dataItem?.id
                      )) && (
                      <p className={classes.highlight}>
                        <i
                          className={`fa fa-circle ${classes.text_danger_glow} ${classes.blink}`}
                          style={{ fontSize: "10px" }}
                        ></i>
                        {lang == "english"
                          ? "Playing"
                          : lang == "spanish"
                          ? "Jugando"
                          : lang == "french"
                          ? "Jouant"
                          : lang == "arabic"
                          ? "تلعب"
                          : "Playing"}
                      </p>
                    )}
                  </div>
                </div>
                <div className=" w-1/2 flex gap-16">
                  <div className="flex justify-center items-center">
                    {dataItem?.viceCaptain == true ? (
                      <Button
                        icon="pi pi-check"
                        onClick={() =>
                          dispatch(removeViceCaptain(dataItem?.id))
                        }
                        rounded
                        size="small"
                        aria-label="Added"
                        text
                      />
                    ) : (
                      <Button
                        icon="pi pi-plus"
                        onClick={() => {
                          AddViceCaptain(dataItem);
                        }}
                        rounded
                        size="small"
                        aria-label="Add"
                        text
                      />
                    )}
                  </div>
                  <div className="flex justify-center items-center">
                    {dataItem?.captain == true ? (
                      <Button
                        icon="pi pi-check"
                        onClick={() => dispatch(removeCaptain(dataItem?.id))}
                        rounded
                        size="small"
                        aria-label="Added"
                        text
                      />
                    ) : (
                      <Button
                        icon="pi pi-plus"
                        onClick={() => {
                          AddCaptain(dataItem);
                        }}
                        rounded
                        size="small"
                        aria-label="Add"
                        text
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className={classes.underline}></div>
            </>
          ))}
        </div>
      </div>
    </Dialog>
  );
};

export default PlayersModal;
