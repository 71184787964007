//  const baseUrl='https://goalalert.gameit.in/';
// const baseUrl = "http://192.168.1.52:5020/";
const baseUrl='/';
export { baseUrl };

const liveMatchesApi = "api/live-matches";
export { liveMatchesApi };

const liveMatchesAllApi = "api/live-matches-all";
export { liveMatchesAllApi };

const currentDayMatchesApi = "api/current-matches";
export { currentDayMatchesApi };

const recentMatchesApi = "api/recent-matches";
export { recentMatchesApi };

const weeklyMatchesApi = "api/weekly-matches";
export { weeklyMatchesApi };

const allMatchesApi = "api/all/matches";
export { allMatchesApi };

const matchByIdApi = "api/match/";
export { matchByIdApi };

const matchPredictionApi = "api/match-prediction/";
export { matchPredictionApi };

const teamLineupsApi = "api/team-lineups";
export { teamLineupsApi };

const matchesByDateApi = "api/matches-date";
export { matchesByDateApi };

const matchStatisticsApi = "api/team-statistics/";
export { matchStatisticsApi };

const playerStatisticsApi = "api/player-statistics/";
export { playerStatisticsApi };

const playersApi = `api/players/`;
export { playersApi };

const signupApi = `api/signup`;
export { signupApi };

// const loginApi = `api/login`;
// export { loginApi };

const logoutApi = `api/logout`;
export { logoutApi };

const standingsApi = "api/standings";
export { standingsApi };

const playerTopScoresApi = `api/player-top-scores`;
export { playerTopScoresApi };

const playerTopAssistsApi = `api/player-top-assists`;
export { playerTopAssistsApi };

const adminGetMatchesApi = `api/admin/get-matches`;
export { adminGetMatchesApi };

const fetchPlayersApi = `api/get-team-players`;
export { fetchPlayersApi };

const createUserTeamApi = `api/create-user-team`;
export { createUserTeamApi };

const getUserMatchesApi = `api/get-user-matches`;
export { getUserMatchesApi };

const getUserTeamApi = `api/get-user-team`;
export { getUserTeamApi };

const getMatchLeaderboardApi = `api/get-match-leaderboard`;
export { getMatchLeaderboardApi };

const userTeamPointsLogicApi = `api/user-team-points-logic`;
export { userTeamPointsLogicApi };

const userRewardsApi = `api/user-rewards`;
export { userRewardsApi };

const fetchMatchContestsApi = `api/match/contests`;
export { fetchMatchContestsApi };

const createUserContestApi = `api/create-user-contest`;
export { createUserContestApi };

const fetchMatchContestByIdApi = `api/get-match-contest`;
export { fetchMatchContestByIdApi };

const checkOtherPlayersTeamApi = `api/check-other-player-teams`;
export { checkOtherPlayersTeamApi };

const checkPlayerStatsApi = `api/check-player-points`;
export { checkPlayerStatsApi };

const userNotificationsApi = `api/user/notifications`;
export { userNotificationsApi };

const userNotificationsCheckApi = `api/user/notifications/check`;
export { userNotificationsCheckApi };

const deleteNotificationApi = `api/user/notification/delete`;
export { deleteNotificationApi };

// predict and win api's

const matchPredictionQuestionsApi = `api/match/prediction/questions`;
export { matchPredictionQuestionsApi };

const saveUserPredictionApi = `api/match/prediction/save-user-prediction`;
export { saveUserPredictionApi };

const getUserPredictionApi = `api/match/prediction/get-user-prediction`;
export { getUserPredictionApi };

const calculatePredictionPointsApi=`api/user-prediction-points-logic`;
export {calculatePredictionPointsApi};

const matchPredictionLeaderboardApi=`api/match/prediction/leaderboard`;
export {matchPredictionLeaderboardApi};

const getUserPredictionMatchesApi=`api/get-user-prediction-matches`;
export {getUserPredictionMatchesApi};

const userPredictionRewardsApi=`api/user/prediction/results`;
export {userPredictionRewardsApi};

// weekly and monthly leaderboard api's

const weeklyLeaderboardApi=`api/this-week/leaderboard/fantasy-game`;
export {weeklyLeaderboardApi};

const previousWeekLeaderboardApi=`api/previous-week/leaderboard/fantasy-game`;
export {previousWeekLeaderboardApi};

const monthlyLeaderboardApi=`api/monthly/leaderboard/fantasy-game`;
export {monthlyLeaderboardApi};

const loginIntergatedApi=`api/login-integrated-api`;
export {loginIntergatedApi};

const deactivateInetgratedApi=`api/deactivate`;
export {deactivateInetgratedApi};

// chandragupt api to check msisdn in subscription table is active or not
const loginApi=`https://www.kickwin.gameit.in/api/login`;
export {loginApi};


// chandragupt api to deactivate number .
const deactivateApi=`https://www.kickwin.gameit.in/api/unsubscription`;
export {deactivateApi};

// chandragupt api to subscribe user.
const subscriptionApi=`https://www.kickwin.gameit.in/api/activateUser`;
export {subscriptionApi};

// chandragupt api to charge number in predict and win contest.
const chargeNumberForPredictAndWin=`https://www.kickwin.gameit.in/PAW/chargeUser`;
export {chargeNumberForPredictAndWin};

// Test Api's

const getMatchesByDateTest = `api/get-matches-by-date-test`;
export { getMatchesByDateTest };