import React from "react";
import classes from "../components/ErrorBoundary.module.css";
import Layout from "../components/Layout";
import Drawer from "../components/Drawer";
import { Button } from "primereact/button";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const { lang } = useSelector((state) => state?.langSlice);
  const navigate = useNavigate();
  return (
    <Layout>
      <Drawer />
      <div className={classes.message_container}>
        <p className={classes.text}>
          {lang == "english"
            ? "Page Not Found!"
            : lang == "spanish"
            ? "¡Página no encontrada!"
            : lang == "french"
            ? "Page non trouvée!"
            : lang == "arabic"
            ? "الصفحة غير موجودة!"
            : "Page Not Found!"}
        </p>
        <Button
          label={
            lang == "english"
              ? "Home"
              : lang == "spanish"
              ? "Hogar"
              : lang == "french"
              ? "Maison"
              : lang == "arabic"
              ? "بيت"
              : "Home"
          }
          raised
          severity="danger"
          onClick={() => navigate("/")}
        />
      </div>
    </Layout>
  );
};

export default PageNotFound;
