import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { loginApi } from "../../api/api";
import axios from "axios";
import { logoutUser } from "../../slices/userSlice";
import { useNavigate } from "react-router-dom";

const Auth = ({ children }) => {
  const { user } = useSelector((state) => state?.userSlice);
  const dispatch=useDispatch();
  const navigate=useNavigate();

  const checkUserBilling = async () => {
    try {
      console.log("checking user....");
      const response = await axios.post(loginApi, {
        msisdn: user?.user_number,
      });
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
      dispatch(logoutUser());
      toast.error("Login Again!");
      navigate("/login");
      // setTimeout(()=>{
      //   window.location.href = `https://www.kickwin.gameit.in/`;
      // },1000);
    }
  };

  useEffect(() => {
    if (!user?.token) {
      // toast.error("You are not Logged In!");
      console.log("no user....");

      navigate("/login");
      
      // setTimeout(() => {
      //   window.location.href = `https://www.kickwin.gameit.in/`;
      // }, 1000);
    } else {
      checkUserBilling();
    }
  }, [user]);
  return <>{children}</>;
};

export default Auth;
