import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import Drawer from "../components/Drawer";
import MenuTabs from "../components/MenuTabs";
import classes from "../css/FantasyGamePage.module.css";
import axios from "axios";
import { baseUrl, weeklyMatchesApi } from "../api/api";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import MatchLayout from "../components/MatchLayout";
import Loader from "../components/Loader";
import { useSelector } from "react-redux";
import date from "../helpers/date";
import MatchGridLayout from "../components/Match-Container/MatchGridLayout";
import HeadingWithGrid from "../components/Headings/HeadingWithGrid";
import MessageBoxSimple from "../components/Message-Box/MessageBoxSimple";

const FantasyGamePage = () => {
  const [matches, setMatches] = useState([]);
  const [todayMatches, setTodayMatches] = useState([]);
  const [tomorrowMatches, setTomorrowMatches] = useState([]);
  const [loading, setLoading] = useState(false);

  const { lang } = useSelector((state) => state.langSlice);

  const fetchWeeklyMatchesFromBackend = async (loader) => {
    try {
      if (loader.loading == true) {
        setLoading(true);
      }
      const res = await axios.post(`${baseUrl}${weeklyMatchesApi}`, {
        timezone: moment.tz.guess(),
      });
      // setMatches(res.data.response);
      if (res?.data?.response?.length > 0) {
        setMatches(
          res?.data?.response.filter(
            (match) =>
              date(match?.time)?.date != "Today" &&
              date(match?.time)?.date != "Tomorrow"
          )
        );
        setTodayMatches(
          res?.data?.response.filter(
            (match) => date(match?.time)?.date == "Today"
          )
        );
        setTomorrowMatches(
          res?.data?.response.filter(
            (match) => date(match?.time)?.date == "Tomorrow"
          )
        );
      }
      setLoading(false);
    } catch (error) {
      toast.error(
        error?.data || error?.data?.message || "Something Went Wrong!"
      );
      console.log(error, "error");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWeeklyMatchesFromBackend({ loading: true });
    const intervalId = setInterval(() => {
      if (!document.hidden) {
        fetchWeeklyMatchesFromBackend({ loading: false });
      }
    }, 15000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Layout active={4} hideMenu={true}>
      <Drawer active={4} />
      <div className={classes.container}>
        <MenuTabs activeIndex={2} />

        {loading ? (
          <Loader />
        ) : (
          <MatchGridLayout>
            <HeadingWithGrid
              title={
                lang == "english"
                  ? "Create Fantasy Team And Win Prizes"
                  : lang == "spanish"
                  ? "Crea un equipo de fantasía y gana premios"
                  : lang == "french"
                  ? "Créez une équipe fantastique et gagnez des prix"
                  : lang == "arabic"
                  ? "أنشئ فريقًا خياليًا واربح الجوائز"
                  : "Create Fantasy Team And Win Prizes"
              }
            />

            {todayMatches?.map((match, index) => {
              if (
                match?.matchStatus != "Match Finished" &&
                match?.matchTime == null
              ) {
                return <MatchLayout match={match} key={index} />;
              }
            })}
            {tomorrowMatches?.map((match, index) => {
              return <MatchLayout match={match} index={index} />;
            })}
            {matches?.length > 0 ? (
              matches?.map((match, index) => {
                return <MatchLayout match={match} key={index} />;
              })
            ) : (
              <MessageBoxSimple
                title={
                  lang == "english"
                    ? "There are no upcoming matches currently in this week.."
                    : lang == "spanish"
                    ? "No hay próximos partidos actualmente en esta semana."
                    : lang == "french"
                    ? "Il n’y a pas de matchs à venir cette semaine."
                    : lang == "arabic"
                    ? "لا توجد مباريات قادمة حاليا في هذا الأسبوع.."
                    : "There are no upcoming matches currently in this week.."
                }
              />
            )}
          </MatchGridLayout>
        )}
      </div>
    </Layout>
  );
};

export default FantasyGamePage;
